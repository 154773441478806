import { useGraphQLClient } from 'providers';
import { FetchPublicBoats, FetchPublicBoatsVariables, useFetchPublicBoats } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useSearchPublicBoats(
  variables: FetchPublicBoatsVariables,
  onSuccess?: (data: FetchPublicBoats) => void,
) {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useFetchPublicBoats(
    graphQLClient,
    variables,
    {
      cacheTime: 0,
      staleTime: 0,
      onSuccess,
    },
  );

  useQueryStatusLogging(queryInfo, 'fetchPublicBoats');

  return queryInfo;
}
