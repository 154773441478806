import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';

import { GetTripLogsVariables, useGetTripLogs } from '../graphql';
import useAccessToken from './useAccessToken';
// import useQueryErrors from './useQueryErrors';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useTripLogs(variables: GetTripLogsVariables, enabled = true) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const hasTripId = !!variables.trip_id;

  // const { handleQueryErrors } = useQueryErrors();
  const queryInfo = useGetTripLogs(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: !!accessToken && enabled && hasTripId,
    keepPreviousData: true,
    onSuccess: () => {
      console.log(Date.now(), `Fetching logs of trip: [${variables.trip_id}] succeed`);
    },
    onError: (errors: any) => {
      // handleQueryErrors(errors);
    },
  });

  useQueryStatusLogging(queryInfo, `logs of trip: [${variables.trip_id}]`);

  return queryInfo;
}
