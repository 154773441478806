import { useGraphQLClient } from 'providers';
import useAccessToken from '../useAccessToken';
import { useDeleteFolder } from '../../graphql';

const useDeleteFolderMutation = () => {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  return useDeleteFolder(graphQLClient);
};

export default useDeleteFolderMutation;
