import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetPublicBoat, GetPublicBoatVariables, useGetPublicBoat } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function usePublicBoat(variables: GetPublicBoatVariables, onSuccess?: (data: GetPublicBoat) => void) {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useGetPublicBoat(graphQLClient, variables, {
    cacheTime: TWO_MINUTES_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    keepPreviousData: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: !!variables.id,
    onSuccess,
  });

  useQueryStatusLogging(queryInfo, 'getPublicBoat');

  return {
    ...queryInfo,
  };
}
