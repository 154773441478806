import { useGraphQLClient } from 'providers';
import { useUpdateBoatManually } from '../graphql';

export default function useUpdateBoatManuallyMutation() {
  const { graphQLClient } = useGraphQLClient();

  const mutationInfo = useUpdateBoatManually(graphQLClient);

  return {
    ...mutationInfo,
  };
}
