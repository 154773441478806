import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetTasksVariables, useGetTasks } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useTasks(variables?: GetTasksVariables, enabled = true) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetTasks(graphQLClient, variables || {}, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    enabled: !!accessToken && enabled,
    onError: (errors: any) => {
      // handleQueryErrors(errors);
    },
    onSuccess: () => {
      console.log(Date.now(), 'Fetching tasks succeed');
    },
  });

  useQueryStatusLogging(queryInfo, 'tasks');

  return queryInfo;
}
