import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useQuery } from '@tanstack/react-query';

type AccessTokenType = 'access_token' | 'files_token';

export default function useAccessToken(options?: GetTokenSilentlyOptions, type: AccessTokenType = 'access_token') {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const getToken = async () => getAccessTokenSilently(options);

  const queryInfo = useQuery([type], getToken, {
    refetchInterval: TWO_HOURS_IN_MILLISECONDS,
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    enabled: isAuthenticated && !isLoading,
  });

  return { ...queryInfo };
}
