import { useGraphQLClient } from 'providers';
import { useUsageDone } from '../graphql';

export default function useUsageDoneMutation() {
  const { graphQLClient } = useGraphQLClient();
  const mutateInfo = useUsageDone(graphQLClient);

  return {
    ...mutateInfo,
  };
}
