import { useGraphQLClient } from 'providers';
import { useMassDeleteFiles } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useDeleteFiles() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  return useMassDeleteFiles(graphQLClient);
}
