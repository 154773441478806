import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useGetCollectionInfo } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useCollectionsInfo() {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useGetCollectionInfo(
    graphQLClient,
    {},
    {
      cacheTime: TWO_MINUTES_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      // refetchInterval:0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        console.log(Date.now(), 'Fetching collections info succeed');
      },
    },
  );

  useQueryStatusLogging(queryInfo, 'collections_info');

  return {
    ...queryInfo,
  };
}
