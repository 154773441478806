import { useConfig } from 'providers';
import { useCallback } from 'react';

interface BlogUrlProps {
  isNewsUrl?: Boolean;
}

export const useBlogUrl = ({ isNewsUrl = false }: BlogUrlProps) => {
  const { theboatDbBlogUrl } = useConfig();
  const getBlogUrl = useCallback(
    (path: string) => theboatDbBlogUrl + `/${isNewsUrl ? 'news/' : ''}` + path,
    [theboatDbBlogUrl, isNewsUrl],
  );

  return { getBlogUrl };
};
