import { GraphQLClient } from 'graphql-request';
import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useConfig } from 'providers';
import { GetNavigationVariables, useGetNavigation } from '../../graphql/cms';

export function useNavigation(variables: GetNavigationVariables) {
  const { cmsGraphQlUrl } = useConfig();

  if (!cmsGraphQlUrl || !cmsGraphQlUrl.length) {
    throw new Error('No cmsGraphQlUrl provided in config.');
  }
  const graphQLClient = new GraphQLClient(cmsGraphQlUrl || '');

  const queryInfo = useGetNavigation(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_HOURS_IN_MILLISECONDS,
    keepPreviousData: true,
    refetchOnMount: true,
  });

  return queryInfo;
}
