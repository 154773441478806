import { useGraphQLClient } from 'providers';
import { useEditTrip } from '../graphql';

export default function useUpdateTripMutation() {
  const { graphQLClient } = useGraphQLClient();

  const mutationInfo = useEditTrip(graphQLClient);

  return mutationInfo;
}
