import { GraphQLClient } from 'graphql-request';
import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useConfig } from 'providers';
import { GetAnnouncementsVariables, useGetAnnouncements } from '../../graphql/cms';

export function useAnnouncements(variables: GetAnnouncementsVariables, enabled = true) {
  const { cmsGraphQlUrl } = useConfig();

  if (!cmsGraphQlUrl || !cmsGraphQlUrl.length) {
    throw new Error('No cmsGraphQlUrl provided in config.');
  }

  const graphQLClient = new GraphQLClient(cmsGraphQlUrl || '');

  return useGetAnnouncements(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_HOURS_IN_MILLISECONDS,
    keepPreviousData: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
}
