import { message } from 'antd';
import { useGraphQLClient } from 'providers';
import { useUpdateFileMutation } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useUpdateFile() {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useUpdateFileMutation(graphQLClient, {
    onSuccess: () => {},
    onError: () => {
      message.error('Something went wrong');
    },
  });

  return {
    ...queryInfo,
  };
}
