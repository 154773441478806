import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetPublicBoatBySlugVariables, useGetPublicBoatBySlug } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function usePublicBoatBySlug(variables: GetPublicBoatBySlugVariables) {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useGetPublicBoatBySlug(
    graphQLClient,
    variables,
    {
      cacheTime: TWO_MINUTES_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      keepPreviousData: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        console.log(Date.now(), 'Fetching getPublicBoatBySlug succeed');
      },
    },
  );

  useQueryStatusLogging(queryInfo, 'getPublicBoatBySlug');

  return {
    ...queryInfo,
  };
}
