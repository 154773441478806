type MenuKeys = {
  key: string;
  validPaths: string[];
  startsWith?: string;
}[];

export const menuKeys: MenuKeys = [
  {
    key: 'maker',
    validPaths: ['/maker'],
  },
  {
    key: 'features',
    validPaths: [
      '/features',
      '/features/my-boats',
      '/features/compare',
      '/features/search-explorer',
      '/features/discover-user-boats',
      '/features/favourite-boats',
      '/features/boats',
      '/features/checklists',
    ],
    startsWith: '/features',
  },
  {
    key: 'more',
    validPaths: ['/pricing', '/how-it-works', '/our-story', '/careers', '/changelog'],
  },
  {
    key: 'boats',
    validPaths: ['/boats'],
    startsWith: '/boat',
  },
];
