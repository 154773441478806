import React, { FC } from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import { SectionTitleProps } from './SearchPanel.props';
import styles from './SearchPanel.module.scss';

const SectionTitle: FC<SectionTitleProps> = ({ title }) => {
  const { results, status } = useInstantSearch();

  return (
    <h3 className={styles.sectionTitle}>
      Found {title} ({results.nbHits})
    </h3>
  );
};

export { SectionTitle };
