import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetUserBoatsVariables, useGetUserBoats } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useUserBoats(variables?: GetUserBoatsVariables) {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetUserBoats(
    graphQLClient,
    {
      page: variables?.page ?? 1,
      first: variables?.first,
      query: variables?.query || '',
      orderBy: variables?.orderBy,
      with_disabled: variables?.with_disabled,
    },
    {
      refetchInterval: TWO_HOURS_IN_MILLISECONDS,
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      enabled: !!accessToken,
    },
  );

  useQueryStatusLogging(queryInfo, 'useUserBoats');

  return {
    ...queryInfo,
  };
}
