import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, Grid, MenuProps } from 'antd';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { ExternalLinkIcon } from '../CustomIcons';
import { MDC_LOGO, MDC_LOGO_MINI, TBA_LOGO, TBA_LOGO_MINI, TBD_LOGO, TBD_LOGO_MINI } from './assets';
import styles from './SelectApp.module.scss';

export interface SelectAppProps {
  /**
   * Select which app is using this component:
   * 'mdc_account' | 'mdc_landing' | 'theboat_app' | 'theboat_db' | 'theboat_landing'
   */
  currentApp:
    | 'mdc_account'
    | 'mdc_landing'
    | 'theboat_app'
    | 'theboat_db'
    | 'theboat_landing'
    | 'theboat_db_blog'
    | 'theboat_app_blog';
  /**
   * Callback to navigate home.
   */
  homeLink?: string;
  /**
   * Condition to switch on Mini logo for Mobile (default: lg)
   * Extract from Grid.useBreakpoint();
   */
  isResponsive?: boolean;

  onHomeClick?: () => void;
  responsive?: boolean;
}

const CheckOutlinedIcon = () => <CheckOutlined style={{ fontSize: 20 }} />;

const SelectApp: FC<SelectAppProps> = ({ currentApp, onHomeClick, homeLink, isResponsive }) => {
  const { lg } = Grid.useBreakpoint();
  const renderSmallIcon = useMemo<boolean>(() => !!(isResponsive || lg), [lg, isResponsive]);

  const logo = useMemo<ReactNode>(() => {
    if (currentApp === 'theboat_app' || currentApp === 'theboat_landing' || currentApp === 'theboat_app_blog') {
      return renderSmallIcon ? <TBA_LOGO /> : <TBA_LOGO_MINI />;
    }

    if (currentApp === 'theboat_db' || currentApp === 'theboat_db_blog') {
      return renderSmallIcon ? <TBD_LOGO /> : <TBD_LOGO_MINI />;
    }

    return renderSmallIcon ? <MDC_LOGO /> : <MDC_LOGO_MINI />;
  }, [currentApp, renderSmallIcon]);

  const targetBlank = useMemo(
    () => ({
      target: '_blank',
      rel: 'noreferrer',
    }),
    [],
  );

  const isTBA = useMemo<boolean>(() => currentApp === 'theboat_app' || currentApp === 'theboat_landing', [currentApp]);
  const isTBD = useMemo<boolean>(() => currentApp === 'theboat_db', [currentApp]);
  const isMDC = useMemo<boolean>(() => currentApp === 'mdc_account' || currentApp === 'mdc_landing', [currentApp]);

  const TBAItemOption = useCallback(
    () => (
      <a href="https://app.theboatapp.com/" {...(isTBA ? {} : targetBlank)}>
        <TBA_LOGO />
        <div className={styles.spacer} />
        {isTBA ? <CheckOutlinedIcon /> : <ExternalLinkIcon />}
      </a>
    ),
    [isTBA, targetBlank],
  );

  const TBDItemOption = useCallback(
    () => (
      <a href="https://theboatdb.com/" {...(isTBD ? {} : targetBlank)}>
        <TBD_LOGO />
        <div className={styles.spacer} />
        {isTBD ? <CheckOutlinedIcon /> : <ExternalLinkIcon />}
      </a>
    ),
    [isTBD, targetBlank],
  );

  const MDCItemOption = useCallback(
    () => (
      <a href="https://account.marinedatacloud.com/">
        <MDC_LOGO />
        <div className={styles.spacer} />
        <CheckOutlinedIcon />
      </a>
    ),
    [],
  );

  const menuItems = useMemo<MenuProps['items']>(() => {
    const items: MenuProps['items'] = [
      { label: <TBAItemOption />, key: 'theboat_app' },
      { label: <TBDItemOption />, key: 'theboat_db' },
    ];

    if (isMDC) {
      items.unshift({ label: <MDCItemOption />, key: 'mdc_account' });
    }

    return items;
  }, [MDCItemOption, TBAItemOption, TBDItemOption, isMDC]);

  const selectedKeys = useMemo<SelectAppProps['currentApp']>(() => {
    if (isMDC) {
      return 'mdc_account';
    }

    if (isTBD) {
      return 'theboat_db';
    }

    return 'theboat_app';
  }, [isMDC, isTBD]);

  return (
    <Dropdown.Button
      placement="bottomRight"
      menu={{
        items: [
          {
            label: 'MARINE DATA CLOUD APPS',
            key: 'apps-group',
            type: 'group',
            children: menuItems,
          },
        ],
        selectable: true,
        selectedKeys: [selectedKeys],
      }}
      icon={<DownOutlined style={{ color: 'var(--gray-8)', fontSize: 20 }} />}
      trigger={['click']}
      overlayClassName={styles.menu}
      onClick={onHomeClick}
    >
      {logo}
    </Dropdown.Button>
  );
};

export default SelectApp;
