import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useMemo } from 'react';
import { InitialDataFunction, useQueryClient } from '@tanstack/react-query';
import { GetCost, GetCosts, GetCostVariables, useGetCost } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useCost(costId?: string | undefined, initialData?: InitialDataFunction<GetCost>) {
  const queryClient = useQueryClient();
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const variables: GetCostVariables = {
    id: costId || '',
  };

  const localInitialData = useMemo<GetCost | undefined>(() => {
    const costsQueries = queryClient.getQueriesData<GetCosts>({ queryKey: ['getCosts'], exact: false });

    let initialCostData;

    for (let i = 0; i < costsQueries.length; i += 1) {
      const [f, costData] = costsQueries[i];
      const cost = costData?.costs?.data.find((item) => item.id === variables.id);

      if (cost) {
        initialCostData = { cost };
        break;
      }
    }

    return initialCostData;
  }, [queryClient, variables.id]);

  const queryInfo = useGetCost(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    // keepPreviousData: true,
    enabled: !!accessToken && !!costId,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: localInitialData || initialData,
    // initialDataUpdatedAt:,
    onSuccess: () => {
      // console.log(`Fetching trip details: [${variables.id}] succeed`);
    },
  });

  return queryInfo;
}
