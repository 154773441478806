import { DocumentNode } from 'graphql';
import { useConfig } from 'providers';
import { useCallback, useState } from 'react';
import useAccessToken from './useAccessToken';

function executeExport(
  mdcGraphQlUrl: string,
  query: DocumentNode,
  variables: Record<string, unknown>,
  headers: Record<string, string>,
) {
  return fetch(mdcGraphQlUrl, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: query.loc?.source.body,
      variables,
    }),
  }).then((e) => e.blob());
}

export default function useExport(exportQuery: DocumentNode) {
  const { data: accessToken } = useAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  const { mdcGraphQlUrl } = useConfig();

  if (!mdcGraphQlUrl) {
    throw new Error('Missing mdcGraphQlUrl!');
  }

  const exportFn = useCallback((variables?: Record<string, unknown>, headers?: Record<string, string>) => {
    setIsLoading(true);

    return executeExport(
      mdcGraphQlUrl || '',
      exportQuery,
      variables || {},
      { ...headers, Authorization: `Bearer ${accessToken}` },
    ).finally(() => {
      setIsLoading(false);
    });
  }, [accessToken, exportQuery, mdcGraphQlUrl]);

  return { doExport: exportFn, isLoading };
}
