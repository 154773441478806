import QuestionOutlined from '@ant-design/icons/QuestionOutlined';
import { Button, ButtonProps, Drawer, Dropdown, Grid, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import styles from './HelpCenter.module.scss';
import { HelpCenterMenu } from './HelpCenterMenu.component';

export type HelpCenterProps = {
  /** Button props */
  buttonProps?: ButtonProps;

  /** Provide which app uses HelpCenter to render different links. */
  app?: 'mdc' | 'tba' | 'tbd';
};

export const HelpCenter: React.FC<HelpCenterProps> = ({ buttonProps, app }) => {
  const { md } = Grid.useBreakpoint();
  const [helpMenuOpen, setHelpMenuOpen] = useState(false);

  // const { formatMessage } = useIntl();
  // const f = (id: string): string => formatMessage({ id: `header.menu.help_and_support.${id}` });

  const helpMenuButton = useMemo(
    () => (
      <Tooltip
        title={'Help & Support'}
        placement="bottom"
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
        color={'var(--gray-1)'}
      >
        <Button
          {...buttonProps}
          icon={<QuestionOutlined />}
          onClick={(e) => {
            e.preventDefault();

            setHelpMenuOpen(true);
          }}
        />
      </Tooltip>
    ),
    [],
  );

  if (!md) {
    return (
      <>
        {helpMenuButton}

        <Drawer
          open={helpMenuOpen}
          title={'Help & Support'}
          width={260}
          className={styles.helpCenterDrawer}
          onClose={() => {
            setHelpMenuOpen(false);
          }}
        >
          <HelpCenterMenu app={app} isDrawer noGroup />
        </Drawer>
      </>
    );
  }

  return (
    <Dropdown
      trigger={['click']}
      overlayClassName={styles.helpCenterDropdown}
      open={helpMenuOpen}
      onOpenChange={setHelpMenuOpen}
      arrow={{ pointAtCenter: true }}
      dropdownRender={() => <HelpCenterMenu app={app} />}
    >
      {helpMenuButton}
    </Dropdown>
  );
};
