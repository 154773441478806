import { useGraphQLClient } from 'providers';
import useAccessToken from '../useAccessToken';
import { useMassDeleteFolders } from '../../graphql';

const useMassDeleteFolderMutation = () => {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  return useMassDeleteFolders(graphQLClient);
};

export default useMassDeleteFolderMutation;
