import { GraphQLClient } from 'graphql-request';
import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useConfig } from 'providers';
import { GetPostsByCategoryVariables, useGetPostsByCategory } from '../graphql/cms';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function usePostsByCategory(variables: GetPostsByCategoryVariables) {
  const { cmsGraphQlUrl } = useConfig();

  if (!cmsGraphQlUrl || !cmsGraphQlUrl.length) {
    throw new Error('No cmsGraphQlUrl provided in config.');
  }
  const graphQLClient = new GraphQLClient(cmsGraphQlUrl || '');

  const queryInfo = useGetPostsByCategory(graphQLClient, variables, {
    cacheTime: TWO_MINUTES_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    keepPreviousData: true,
    // refetchInterval:0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      console.log(Date.now(), 'Fetching collections info succeed');
    },
  });

  useQueryStatusLogging(queryInfo, 'collections_info');

  return {
    ...queryInfo,
  };
}
