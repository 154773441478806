import React from 'react';
import styles from '@styles/Footer/main.module.scss';
import Header from './Header';
import Address from './Address';
import Copyright from './Copyright';
import { useNavigation } from 'services/hooks/queries/useNavigation';
import OtherMenu from './OtherMenu';

const Footer: React.FC = () => {
  const { data } = useNavigation({ navigationIdOrSlug: '2' });

  return (
    <footer className={styles.container}>
      <Header />

      <div className={styles.container__menu}>
        {data?.renderNavigation?.length > 0 &&
          data?.renderNavigation.map((navigation) => {
            const showMoreLink = {
              text: 'View all',
              url: navigation.path,
            };
            const isInternal =
              !!navigation.items && navigation.items.length > 0 && navigation.items?.[0]?.type === 'INTERNAL';

            return (
              <OtherMenu
                key={navigation.id}
                menuItems={navigation.items}
                title={navigation.title}
                showMoreLink={isInternal && showMoreLink}
              />
            );
          })}
      </div>

      <Address />

      <Copyright />
    </footer>
  );
};

export default Footer;
