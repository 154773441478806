import { ONE_DAY_IN_MILLISECONDS, TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useGetCountries } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useCountries() {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useGetCountries(
    graphQLClient,
    {},
    {
      cacheTime: ONE_DAY_IN_MILLISECONDS,
      staleTime: TWO_HOURS_IN_MILLISECONDS,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        console.log(Date.now(), 'Fetching countries succeed');
      },
    },
  );

  useQueryStatusLogging(queryInfo, 'countries');

  return {
    ...queryInfo,
  };
}
