import { useGraphQLClient } from 'providers';
import { useEditExpense } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useUpdateExpenseMutation() {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);
  // const { handleQueryErrors } = useQueryErrors();

  const mutationInfo = useEditExpense(graphQLClient, {
    onError: (errors: any) => {
      // handleQueryErrors(errors);
    },
  });

  return mutationInfo;
}
