import { QueryKey, UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetBoatDetails, GetUserBoats, useGetBoatDetails } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';
import { useMemo } from 'react';

export default function useBoatDetails(
  id: string = '',
  options?: UseQueryOptions<GetBoatDetails, unknown, GetBoatDetails, QueryKey>,
) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const initialBoatData = useMemo<GetBoatDetails['boatDetail'] | undefined>(() => {
    const getUserBoatsQuery = queryClient.getQueriesData({ queryKey: ['getUserBoats'], exact: false, type: 'all' });

    if (getUserBoatsQuery.length && getUserBoatsQuery[0].length > 1) {
      const [[queryKey, getUserBoatsData]] = getUserBoatsQuery;
      const userBoatsData: GetUserBoats | undefined = getUserBoatsData as GetUserBoats;

      return userBoatsData?.boatDetails?.data.find((item) => item.id === id) as GetBoatDetails['boatDetail'];
    }

    return undefined;
  }, [queryClient, id]);

  const placeholderData = { boatDetail: initialBoatData };

  const queryInfo = useGetBoatDetails(
    graphQLClient,
    {
      id,
    },
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      keepPreviousData: true,
      enabled: !!accessToken && !!id && id !== '',
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      placeholderData,
      initialDataUpdatedAt: 0,
      ...options,
    },
  );

  useQueryStatusLogging(queryInfo, 'getBoatDetails');

  return {
    ...queryInfo,
  };
}
