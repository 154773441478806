import { useGraphQLClient } from 'providers';
import { useAddCategory } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useAddCategoryMutation() {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const mutationInfo = useAddCategory(graphQLClient);

  return {
    ...mutationInfo,
  };
}
