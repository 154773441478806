import { GraphQLClient } from 'graphql-request';
import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useConfig } from 'providers';
import { GetChangelogsVariables, useInfiniteGetChangelogs } from '../graphql/cms';

export function useInfiniteChangelogs(variables: GetChangelogsVariables) {
  const { cmsGraphQlUrl } = useConfig();

  if (!cmsGraphQlUrl) {
    throw new Error('No cmsGraphQlUrl configured'!);
  }
  const graphQLClient = new GraphQLClient(cmsGraphQlUrl || '');

  const queryInfo = useInfiniteGetChangelogs(graphQLClient, variables, {
    cacheTime: TWO_MINUTES_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    getNextPageParam: ({ changelogs }) => {
      const currentPage = changelogs?.meta?.pagination?.page || 1;

      const total = changelogs?.meta?.pagination?.total || 0;
      const pageSize = changelogs?.meta?.pagination?.pageSize || 10;
      const hasNextPage = currentPage * pageSize < total;

      if (hasNextPage) {
        const nextPage = currentPage + 1;

        return {
          page: nextPage,
        };
      }

      return undefined;
    },
  });

  return {
    ...queryInfo,
  };
}
