import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetActivityLogsVariables, useGetActivityLogs } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useActivityLogs(variables: GetActivityLogsVariables, onError?: (err: unknown) => void) {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetActivityLogs(
    graphQLClient,
    variables,
    {
      cacheTime: TWO_MINUTES_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      keepPreviousData: true,
      enabled: !!accessToken,
      onError,
    },
  );

  useQueryStatusLogging(queryInfo, 'getActivityLogs');

  return {
    ...queryInfo,
  };
}
