import { GraphQLClient } from 'graphql-request';
import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useConfig } from 'providers';
import { GetChangelogsVariables, useGetChangelogs } from '../graphql/cms';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useChangelogs(variables:GetChangelogsVariables) {
  const { cmsGraphQlUrl } = useConfig();

  if (!cmsGraphQlUrl || !cmsGraphQlUrl.length) {
    throw new Error('No cmsGraphQlUrl provided in config.');
  }
  const graphQLClient = new GraphQLClient(cmsGraphQlUrl || '');

  const queryInfo = useGetChangelogs(
    graphQLClient,
    variables,
    {
      cacheTime: TWO_MINUTES_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      // refetchInterval:0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        console.log(Date.now(), 'Fetching collections info succeed');
      },
    },
  );

  useQueryStatusLogging(queryInfo, 'collections_info');

  return {
    ...queryInfo,
  };
}
