import { useGraphQLClient } from 'providers';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { Exact, GetFavorites, RemoveFromFavorites, useRemoveFromFavorites } from '../graphql';

export default function useRemoveFromFavoriteMutation(
  options?: UseMutationOptions<RemoveFromFavorites, unknown, Exact<{ boat_id: string }>, unknown> | undefined,
) {
  const { graphQLClient } = useGraphQLClient();

  const queryClient = useQueryClient();

  const oneRemoveSuccess = (_data: RemoveFromFavorites, variables: Exact<{ boat_id: string }>) => {
    queryClient.setQueryData(['getFavorites'], (prev?: GetFavorites): GetFavorites => {
      if (prev) {
        return {
          favorites: {
            ...prev.favorites,
            // @ts-ignore: Problem with code-generator types
            data: prev.favorites?.data.filter((item) => item.favoritable_id !== variables.boat_id),
          },
        };
      }

      return {
        favorites: {
          data: [],
          paginatorInfo: {
            currentPage: 0,
            count: 0,
            total: 0,
          },
        },
      };
    });
  };

  const queryInfo = useRemoveFromFavorites(graphQLClient, {
    ...options,
    onSuccess: oneRemoveSuccess,
  });

  return {
    ...queryInfo,
  };
}
