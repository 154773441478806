import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useGetCurrentUserData } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useCurrentUser() {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetCurrentUserData(
    graphQLClient,
    {},
    {
      refetchInterval: TWO_HOURS_IN_MILLISECONDS,
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      enabled: !!accessToken,
    },
  );

  return {
    ...queryInfo,
  };
}
