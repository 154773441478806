import { useQueryClient } from '@tanstack/react-query';
import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useMemo } from 'react';
import { useGetFile } from '../../graphql';
import useAccessToken from '../useAccessToken';

export default function useFile(fileId?: string) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const localInitialData = useMemo(() => {
    if (fileId) {
      const filesQueries = queryClient.getQueryCache().findAll(['getPaginatedFiles'], { exact: false });

      let initialFileData;

      for (let i = 0; i < filesQueries?.length; i += 1) {
        // @ts-ignore
        if (filesQueries[i].state?.data?.files?.data.length > 0) {
          // @ts-ignore
          const file = filesQueries[i].state?.data?.files?.data?.find((item) => item.id === fileId.toString());

          if (file) {
            initialFileData = { file };
            break;
          }
        }
      }

      return initialFileData;
    }
  }, [queryClient, fileId]);

  const queryInfo = useGetFile(
    graphQLClient,
    {
      id: fileId,
    },
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      enabled: !!accessToken && !!fileId,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      initialData: localInitialData,
    },
  );

  return queryInfo;
}
