import { RightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import Link from 'next/link';
import { FC } from 'react';
import styles from './BoatsMenu.module.scss';
import { SectionItemProps } from './BoatsMenu.props';

const { Title } = Typography;

const SectionItem: FC<SectionItemProps> = ({ title, id, items, path }) => (
  <>
    <Title level={3} className={styles.sectionTitle} ellipsis={{ rows: 1 }}>
      {title}
    </Title>
    <>
      {items.length && (
        <ul>
          {items.map((item) => (
            <li key={item.id}>
              <Link href={item.path} title={item.title}>
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
    <Link href={path} className={styles.viewAllLink} title={title}>
      View all <RightOutlined />
    </Link>
  </>
);

export default SectionItem;
