import { useGraphQLClient } from 'providers';
import useAccessToken from '../useAccessToken';
import { useMassMove } from '../../graphql';

const useMassMoveMutation = () => {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  return useMassMove(graphQLClient);
};

export default useMassMoveMutation;
