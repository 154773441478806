import Image from 'next/image';
import { FC } from 'react';
import { BoatCardImageProps } from './BoatCardImage.props';
import BoatImagePlaceholder from 'sailor-ui/PublicBoatCard/assets/no-image-placeholder.svg';

const BoatCardImage: FC<BoatCardImageProps> = ({ image, height, width }) => {
  if (image?.data?.attributes?.url) {
    return (
      <Image
        src={image.data.attributes.url}
        width={width}
        height={height}
        alt={image?.data?.attributes?.alternativeText || 'Boat'}
      />
    );
  }

  return (
    <div style={{ width: '100%', height, overflow: 'hidden' }}>
      <BoatImagePlaceholder />
    </div>
  );
};

export { BoatCardImage };
