import { RightOutlined } from '@ant-design/icons';
import Link from 'next/link';
import React from 'react';
import styles from '@styles/Footer/menu.module.scss';

type Props = {
  title: String;
  badge?: string;
  count?: string | number;
  showMoreLink?: {
    text: string;
    url?: string;
  };
  menuItems: any;
};

const OtherMenu = ({ title, badge, menuItems, showMoreLink }: Props) => (
  <div className={styles.container}>
    <div className={styles.container__title}>
      <h4>{title}</h4>

      {badge && <p className={styles.container__badge}>{badge.toUpperCase()}</p>}
    </div>

    <ul className={styles.container__menu}>
      {menuItems.map((item) => {
        const { title } = item;

        return (
          <li key={item.title} className={styles.container__item}>
            {item.type === 'INTERNAL' ? (
              <a title={title} href={item.path}>
                {title}
              </a>
            ) : item.title === 'Contact us' ? (
              <a title={title} href="mailto:Support@MarineDataCloud.com?subject=Support needed for TheBoatDB">
                {title}
              </a>
            ) : (
              <Link href={item.path} title={title} target="_blank">
                {title}
              </Link>
            )}
          </li>
        );
      })}

      {showMoreLink && (
        <li className={styles.container__item__more}>
          <Link href={`${showMoreLink.url}`}>
            {showMoreLink.text}
            <RightOutlined style={{ fontSize: '13px' }} />
          </Link>
        </li>
      )}
    </ul>
  </div>
);

export default OtherMenu;
