import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetFavoritesVariables, useGetFavorites } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useFavorites(variables?:GetFavoritesVariables) {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetFavorites(
    graphQLClient,
    variables,
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      keepPreviousData: true,
      enabled: !!accessToken,
      onSuccess: () => {
        console.log(Date.now(), 'Fetching public boats succeed');
      },
    },
  );

  useQueryStatusLogging(queryInfo, 'publicBoats');

  return queryInfo;
}
