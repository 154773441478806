export function useQueryStatusLogging(
  {
    isFetching,
  }: {
    isFetching: boolean;
  },
  text: string,
) {
  // useEffect(() => {
  //   if (isFetching) {
  //     console.log(Date.now(), `Fetching ${text}...`);
  //   }
  // }, [isFetching, text]);
}
