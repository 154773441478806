import { DEFAULT_PER_PAGE } from 'mdc-constants';

export const logsOfTripScreenVariables = {
  keyword: '',
  first: DEFAULT_PER_PAGE,
  orderBy: [
    {
      column: 'datetime',
      order: 'desc',
    },
  ],
};
