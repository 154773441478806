import { useQuery } from '@tanstack/react-query';
import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useMemo } from 'react';
import useSharedWithMutation from './useSharedWithMutation';
import useAccessToken from './useAccessToken';
import { Scalars, SharedWithVariables } from '../graphql';

// type SharedWithVariables = {
//   model_id?: Scalars['ID'];
//   model_type?: Scalars['String'];
// };

export const useSharedWithQuery = (variables: SharedWithVariables) => {
  const { mutateAsync } = useSharedWithMutation();
  const { data: accessToken } = useAccessToken();

  const getSharedWith = () => {
    if (variables.model_id && variables.model_type) {
      return mutateAsync({ model_id: variables.model_id, model_type: variables.model_type });
    }

    return null;
  };

  const enabled = useMemo<boolean>(
    () => !!accessToken && !!variables.model_id && !!variables.model_type,
    [accessToken, variables],
  );

  return useQuery(['sharedWithQuery', variables], getSharedWith, {
    refetchInterval: TWO_HOURS_IN_MILLISECONDS,
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    enabled,
  });
};
