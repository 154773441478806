import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetFilesVariables, useInfiniteGetFiles } from '../graphql';
import { useQueryClient } from '@tanstack/react-query';
import { GetFilesDataItem } from '../types';
import useAccessToken from './useAccessToken';

function updateFilesCacheAfterDeleteFn(deletedIds: string[]) {
  return (old: any) => ({
    ...old,
    pages: old.pages.map((page: any) => ({
      ...page,
      files: {
        ...page.files,
        data: page.files.data.filter((e: any) => !deletedIds.includes(e.id)),
      },
    })),
  });
}

function updateFilesCacheAfterEditFn(updatedFile: GetFilesDataItem) {
  return (old: any) => ({
    ...old,
    pages: old.pages.map((page: any) => ({
      ...page,
      files: {
        ...page.files,
        data: page.files.data.map((file: any) => {
          if (file.id === updatedFile.id) {
            return updatedFile;
          }

          return file;
        }),
      },
    })),
  });
}

function updateFilesCacheAfterUploadFn(uploadedFile: GetFilesDataItem) {
  return (old: any) => ({
    ...old,
    pages: old.pages.map((page: any, idx: any) => {
      if (idx === 0) {
        return {
          ...page,
          files: {
            ...page.files,
            data: [uploadedFile, ...page.files.data],
          },
        };
      }

      return page;
    }),
  });
}

export default function useFiles(variables?: GetFilesVariables, enabled: boolean = true) {
  const { data: accessToken } = useAccessToken();
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useInfiniteGetFiles(
    graphQLClient,
    {
      type: variables?.type,
      is_filepicker: variables?.is_filepicker,
      folder_id: variables?.folder_id,
    },
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      suspense: false,
      enabled: !!accessToken && enabled,
      getNextPageParam: ({ files }) => {
        const { currentPage, hasMorePages } = files?.paginatorInfo || {};

        if (hasMorePages && currentPage) {
          return currentPage + 1;
        }

        return undefined;
      },
    },
  );

  /**
   * Used to access protected file and images
   * @param src original file url
   * @returns url with the access token as query parameter
   */
  const getFileUrlWithToken = (src?: string | null): string | null => {
    if (src) {
      return `${src}?token=${accessToken}`;
    }

    return null;
  };

  return {
    ...queryInfo,
    getFileUrlWithToken,
    updateFilesCacheAfterDelete: (deletedIds: string[]) => {
      queryClient.cancelQueries(['getFiles.infinite', variables]);
      queryClient.setQueryData(['getFiles.infinite', variables], updateFilesCacheAfterDeleteFn(deletedIds));
    },
    updateFilesCacheAfterEdit: (updatedFile: GetFilesDataItem) => {
      queryClient.cancelQueries(['getFiles.infinite', variables]);
      queryClient.setQueryData(['getFiles.infinite', variables], updateFilesCacheAfterEditFn(updatedFile));
    },
    updateFilesCacheAfterUpload: (uploadedFile: GetFilesDataItem) => {
      queryClient.cancelQueries(['getFiles.infinite', variables]);
      queryClient.setQueryData(['getFiles.infinite', variables], updateFilesCacheAfterUploadFn(uploadedFile));
    },
  };
}
