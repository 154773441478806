import { useGraphQLClient } from 'providers';
import { useDeleteFileMutation } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useDeleteFile() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  return useDeleteFileMutation(graphQLClient);
}
