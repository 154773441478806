import { Limit } from '../graphql';
import useLimits from './queries/useLimits';
import { useMemo } from 'react';

const useLimitByName = (
  limitName:
    | 'boat_count'
    | 'boat_count_per_plan'
    | 'offline'
    | 'custom_fields'
    | 'storage'
    | 'sharing'
    | 'calendar'
    | 'syndication'
    | 'logs'
    | 'google_maps'
    | 'windy'
    | 'operational_logs'
    | 'inventory'
    | 'maintenance'
    | 'task_sync'
    | 'locations'
    | 'sub_locations'
    | 'task'
    | 'inventory_sync'
    | 'checklist'
    | 'cost_module'
    | 'category'
    | 'assignable_alerts'
    | 'alerts'
    | 'task_dashboard'
    | 'alert_dashboard'
    | 'trip_dashboard'
    | 'cost_dashboard',
): Limit & {
  isLoadingLimits: boolean;
} => {
  const { data: limitsData, isLoading: isLoadingLimits } = useLimits();

  const limitItem: Limit | undefined = useMemo(
    () => limitsData?.limits?.find((item) => item?.name === limitName),
    [limitName, limitsData?.limits],
  );

  if (limitItem) {
    return { ...limitItem, isLoadingLimits: isLoadingLimits };
  }

  return { isLoadingLimits: isLoadingLimits, is_allowed: false, name: limitName, total: 0, used: 0, type: 'unknown' };
};

export default useLimitByName;
