import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetPaginatedFilesVariables, useGetPaginatedFiles } from '../../graphql';
import useAccessToken from '../useAccessToken';

export default function usePaginatedFiles(variables?: GetPaginatedFilesVariables) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetPaginatedFiles(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    suspense: false,
    enabled: !!accessToken,
  });

  return queryInfo;
}
