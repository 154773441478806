import { useGraphQLClient } from 'providers';
import { useUpdateBoatFromDb } from '../graphql';

export default function useUpdateBoatFromDBMutation() {
  const { graphQLClient } = useGraphQLClient();

  const mutationInfo = useUpdateBoatFromDb(graphQLClient);

  return {
    ...mutationInfo,
  };
}
