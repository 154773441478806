import { useGraphQLClient } from 'providers';
import { useAddBoatManually } from '../graphql';

export default function useAddBoatManuallyMutation() {
  const { graphQLClient } = useGraphQLClient();

  const mutationInfo = useAddBoatManually(graphQLClient);

  return {
    ...mutationInfo,
  };
}
