import { useGraphQLClient } from 'providers';
import useAccessToken from '../useAccessToken';
import { useUpdateFolder } from '../../graphql';

const useUpdateFolderMutation = () => {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  return useUpdateFolder(graphQLClient);
};

export default useUpdateFolderMutation;
