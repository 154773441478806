import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { notification } from 'antd';
import { GetChecklist, GetChecklistVariables, GetMyChecklists, GetPublicChecklist, useGetChecklist } from '../graphql';
import useAccessToken from './useAccessToken';
import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

export default function useChecklist(variables?: GetChecklistVariables) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const localInitialData = useMemo<GetChecklist | undefined>(() => {
    const myChecklistsQueries = queryClient.getQueriesData<GetMyChecklists>(['getMyChecklists']);
    const publicChecklistsQueries = queryClient.getQueriesData<GetPublicChecklist>(['getPublicChecklist']);
    let initialChecklistData: GetChecklist | undefined = undefined;

    for (let i = 0; i < myChecklistsQueries.length; i += 1) {
      const [f, myChecklistData] = myChecklistsQueries[i];

      const myChecklist = myChecklistData?.myChecklists?.data.find((item) => item.id === variables?.id);

      if (myChecklist) {
        initialChecklistData = {
          checklist: myChecklist,
        };
        break;
      }
    }

    for (let i = 0; i < publicChecklistsQueries.length; i += 1) {
      const [f, publicChecklistData] = publicChecklistsQueries[i];

      const publicChecklist = publicChecklistData?.publicChecklists?.data.find((item) => item.id === variables?.id);

      if (publicChecklist) {
        initialChecklistData = {
          checklist: publicChecklist,
        };
        break;
      }
    }

    if (initialChecklistData) {
      return initialChecklistData;
    }

    return undefined;
  }, [queryClient, variables?.id]);

  const queryInfo = useGetChecklist(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    enabled: !!accessToken && !!variables?.id,
    initialData: localInitialData,
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'Something went wrong. Data can not be loaded from server.',
      });
    },
  });

  return {
    ...queryInfo,
  };
}
