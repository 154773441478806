import { useGraphQLClient } from 'providers';
import { useSharedWith } from '../graphql';

export default function useSharedWithMutation() {
  const { graphQLClient } = useGraphQLClient();

  const mutationInfo = useSharedWith(graphQLClient);

  return {
    ...mutationInfo,
  };
}
