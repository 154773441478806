import { FC, useEffect } from 'react';
import { useAnnouncements } from 'services/hooks/queries/useAnnouncements';
import AnnouncementLevel from './components/AnnouncementLevel.component';
import dayjs, { extend } from 'dayjs';
import { useRemoveExpiredIdsFromLocalStorage } from '@marine/lib';
import utc from 'dayjs/plugin/utc';
import { useConfig } from 'providers';

extend(utc);

const currentDate = dayjs.utc().toISOString();

const AnnouncementAlerts: FC = () => {
  const { appCode } = useConfig();
  const removeExpiredIdsFromLocalStorage = useRemoveExpiredIdsFromLocalStorage();

  const { data: maintenanceData, isFetched } = useAnnouncements({ appCode: appCode, expirationDate: currentDate });

  useEffect(() => {
    const fetchedIds = maintenanceData?.announcements?.data?.map((announcement) => announcement?.id);

    if (isFetched) {
      removeExpiredIdsFromLocalStorage(fetchedIds);
    }
  }, [maintenanceData?.announcements?.data, removeExpiredIdsFromLocalStorage, isFetched]);

  return (
    <div>
      {maintenanceData?.announcements?.data.map((announcement) => (
        <AnnouncementLevel maintenanceData={announcement} key={announcement?.id} />
      ))}
    </div>
  );
};

export { AnnouncementAlerts };
