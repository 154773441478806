import React from 'react';

import { useIntl } from 'react-intl';

import styles from '@styles/Footer/address.module.scss';

const Address: React.FC = () => {
  const { formatMessage } = useIntl();
  const f = (id: string): string => formatMessage({ id: `theboat_db.footer.information.${id}` });

  return (
    <div className={styles.container}>
      <div className={styles.container__logo}>
        <img src="/images/theboatdb-logo-white.svg" alt="TheBoatDB Logo" />
      </div>

      <p className={styles.container__info} dangerouslySetInnerHTML={{ __html: f('info') }} />

      <p className={styles.container__address} dangerouslySetInnerHTML={{ __html: f('address') }} />
    </div>
  );
};

export default Address;
