import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Link from 'next/link';
import { useConfig } from 'providers';
import { FC, useState } from 'react';
import { GetRecentNews, GetRecentPosts } from 'services/graphql/cms';

interface MobileBlogMenuProps {
  postsData: GetRecentPosts;
  newsData: GetRecentNews;
}

const MobileBlogMenu: FC<MobileBlogMenuProps> = ({ newsData, postsData }) => {
  const [blogMenuOpen, setBlogMenuOpen] = useState<boolean>();
  const [newsMenuOpen, setNewsMenuOpen] = useState<boolean>();
  const { theboatDbBlogUrl } = useConfig();

  const news = newsData?.newsItems?.data;
  const blogs = postsData?.posts?.data;

  return (
    <Menu
      mode="inline"
      items={[
        {
          key: 'blog',
          label: (
            <span>
              Blog
              <DownOutlined
                style={{
                  transform: `rotate(${blogMenuOpen ? 180 : 0}deg)`,
                }}
              />
            </span>
          ),
          onTitleClick: () => setBlogMenuOpen(!blogMenuOpen),
          children: blogs?.map((item) => ({
            key: item?.attributes?.slug,
            label: (
              <Link target="_blank" rel="noopener noreferrer" href={`${theboatDbBlogUrl}/${item?.attributes?.slug}`}>
                {item?.attributes?.title}
              </Link>
            ),
          })),
        },
        {
          key: 'news',
          label: (
            <span>
              News
              <DownOutlined
                style={{
                  transform: `rotate(${newsMenuOpen ? 180 : 0}deg)`,
                }}
              />
            </span>
          ),
          onTitleClick: () => setNewsMenuOpen(!newsMenuOpen),
          children: news?.map((item) => ({
            key: item?.attributes?.slug,
            label: (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`${theboatDbBlogUrl}/news/${item?.attributes?.slug}`}
              >
                {item?.attributes?.title}
              </Link>
            ),
          })),
        },
      ]}
    />
  );
};

export { MobileBlogMenu };
