import { Grid } from 'antd';
import {
  useMemo, useEffect, useRef, useState,
} from 'react';

const { useBreakpoint } = Grid;

interface ScrollHandler {
  /** Set breakpoint when header changes to drawer */
  breakpoint: boolean;
}

const useScrollHandler = (responsive?: ScrollHandler) => {
  const { xl } = useBreakpoint();
  const [stickyHeader, setStickyHeader] = useState(false);
  const prevScroll = useRef(0);

  const responsiveWidth = useMemo(() => {
    if (responsive?.breakpoint !== undefined) {
      return responsive.breakpoint;
    }

    return xl;
  }, [xl, responsive]);

  useEffect(() => {
    const handleScroll = (): void => {
      const currentScroll = window?.pageYOffset;

      if (currentScroll <= 0) {
        setStickyHeader(false);

        return;
      }

      if (currentScroll > 100 && !responsiveWidth) {
        if (currentScroll > prevScroll.current && !stickyHeader) {
          setStickyHeader(true);
        } else if (currentScroll < prevScroll.current && stickyHeader) {
          setStickyHeader(false);
        }
      } else {
        setStickyHeader(false);
      }

      prevScroll.current = currentScroll;
    };

    window?.addEventListener('scroll', handleScroll);

    return () => {
      window?.removeEventListener('scroll', handleScroll);
    };
  }, [stickyHeader, responsiveWidth]);

  return {
    stickyHeader,
  };
};

export default useScrollHandler;
