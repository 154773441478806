import { useAuth0 } from '@auth0/auth0-react';
import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useEffect, useMemo } from 'react';
import { GetFilteredBoatsVariables, useGetFilteredBoats } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useFilterBoats(variables:GetFilteredBoatsVariables) {
  const { data: accessToken, isLoading: isLoadingToken } = useAccessToken();
  const { isLoading: isLoadingAuthentication } = useAuth0();

  // eslint-disable-next-line arrow-body-style
  const enabledQuery = useMemo(() => {
    return !isLoadingAuthentication && !isLoadingToken;
  }, [isLoadingAuthentication, isLoadingToken]);
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetFilteredBoats(
    graphQLClient,
    variables,
    {
      enabled: enabledQuery,
      cacheTime: TWO_MINUTES_IN_MILLISECONDS,
      keepPreviousData: true,
      // staleTime: TWO_MINUTES_IN_MILLISECONDS,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        console.log(Date.now(), 'Fetching filtered boats succeed');
      },
    },
  );

  useEffect(() => {
    if (enabledQuery && accessToken) {
      queryInfo.refetch();
    }
  }, [enabledQuery]);

  useQueryStatusLogging(queryInfo, 'filteredBoats');

  return {
    ...queryInfo,
  };
}
