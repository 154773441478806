import { useGraphQLClient } from 'providers';
import { useEditTask } from '../graphql';

export default function useUpdateTaskMutation() {
  const { graphQLClient } = useGraphQLClient();

  const mutationInfo = useEditTask(graphQLClient);

  return {
    ...mutationInfo,
  };
}
