import { RightOutlined } from '@ant-design/icons';
import { useBlogUrl } from '@app/hooks/useBlogUrl';
import { Skeleton } from 'antd';
import React from 'react';
import styles from './BlogMenu.module.scss';
import { SectionItemProps } from './BlogMenu.props';
import BlogPostCard from './BlogPostCard';

const SectionItem: React.FC<SectionItemProps> = ({ isLoading, items, title, path = '' }) => {
  const { getBlogUrl } = useBlogUrl({});

  return (
    <div>
      <h3 className={styles.sectionTitle}>{title}</h3>
      {isLoading ? (
        <>
          {[0, 1].map((_, loadingIndex) => (
            <Skeleton
              key={loadingIndex}
              loading
              avatar
              active
              paragraph={{ rows: 2 }}
              className={styles.postsSkeleton}
            />
          ))}
        </>
      ) : (
        <>
          {items.length &&
            items.map((item) => (
              <BlogPostCard
                key={item.id}
                title={item.attributes.title}
                slug={item.attributes.slug}
                image={item.attributes.featuredImage.data.attributes}
                excerpt={item.attributes.excerpt}
                isNewsBlog={path === 'news' ? true : false}
              />
            ))}
        </>
      )}
      <a href={getBlogUrl(path)} className={styles.viewAllLink}>
        View all <RightOutlined />
      </a>
    </div>
  );
};

export default SectionItem;
