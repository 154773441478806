import { useGraphQLClient } from 'providers';
import { useUploadFile } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useFileUpload() {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useUploadFile(graphQLClient);

  return {
    ...queryInfo,
  };
}
