import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetStatisticsVariables, useGetStatistics } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useStatistics(variables?: GetStatisticsVariables, enabled = true) {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryInfo = useGetStatistics(graphQLClient, variables, {
    enabled: !!accessToken && enabled,
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (errors: any) => {
      console.log('🚀 ~ file: useStatistics.ts ~ line 18 ~ useStatistics ~ errors', errors);
      // handleQueryErrors(errors);
    },
    onSuccess: () => {
      console.log(Date.now(), 'Fetched statistics');
    },
  });

  useQueryStatusLogging(queryInfo, 'statistics');

  return queryInfo;
}
