import { useGraphQLClient } from 'providers';
import useAccessToken from '../useAccessToken';
import { useMassDeleteFiles } from '../../graphql';

const useMassDeleteFilesMutation = () => {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  return useMassDeleteFiles(graphQLClient);
};

export default useMassDeleteFilesMutation;
