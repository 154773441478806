import { useSearchPosts, SearchPostsVariables, useGetPostsByCategory } from './../graphql/cms';
import { GraphQLClient } from 'graphql-request';
import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useConfig } from 'providers';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function usePostsSearch(variables: SearchPostsVariables) {
  const { cmsGraphQlUrl } = useConfig();

  if (!cmsGraphQlUrl || !cmsGraphQlUrl.length) {
    throw new Error('No cmsGraphQlUrl provided in config.');
  }
  const graphQLClient = new GraphQLClient(cmsGraphQlUrl || '');

  const queryInfo = useSearchPosts(graphQLClient, variables, {
    cacheTime: TWO_MINUTES_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    keepPreviousData: true,
  });

  useQueryStatusLogging(queryInfo, 'collections_info');

  return {
    ...queryInfo,
  };
}
