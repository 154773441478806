import { message } from 'antd';
import { useGraphQLClient } from 'providers';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteExpense } from '../graphql';
import useAccessToken from './useAccessToken';

const useDeleteExpenseMutation = () => {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const queryInfo = useDeleteExpense(graphQLClient, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getExpenses']);
    },
    onError: (data: { message: string }) => {
      // message.error(data.message.split(':')[0]);
      message.error("Log couldn't be deleted");
    },
  });

  return {
    ...queryInfo,
  };
};

export default useDeleteExpenseMutation;
