import { useGraphQLClient } from 'providers';
import { useAddToFavorite } from '../graphql';
import { useQueryClient } from '@tanstack/react-query';

export default function useAddToFavoriteMutation() {
  const { graphQLClient } = useGraphQLClient();
  const queryClient = useQueryClient();

  const queryInfo = useAddToFavorite(graphQLClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getFavorites'] });
    },
  });

  return {
    ...queryInfo,
  };
}
