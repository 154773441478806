import { useGraphQLClient } from 'providers';
import useAccessToken from '../useAccessToken';
import { useAddFolder } from '../../graphql';

const useAddFolderMutation = () => {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  return useAddFolder(graphQLClient);
};

export default useAddFolderMutation;
