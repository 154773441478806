import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetExpensesVariables, useGetExpenses } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useExpenses(variables?: GetExpensesVariables, enabled = true) {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryInfo = useGetExpenses(graphQLClient, variables, {
    enabled: !!accessToken && enabled,
    cacheTime: TWO_MINUTES_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    onError: (errors: any) => {
      console.log('🚀 ~ file: useStatistics copy.ts ~ line 19 ~ useExpenses ~ errors', errors);
    },
    onSuccess: () => {
      console.log(Date.now(), 'Fetched expenses');
    },
  });

  useQueryStatusLogging(queryInfo, 'expenses');

  return queryInfo;
}
