import { FormInstance, notification } from 'antd';
import { FieldData, FieldError } from 'rc-field-form/es/interface';
import { useCallback } from 'react';

type Options = {
  apiNotification?: boolean;
  validationNotification?: boolean;
};

export default function useHandleFormErrors(form: FormInstance, setErrors?: (errors: FieldData[]) => void) {
  const handleErrors = useCallback(
    (error: any, options?: Options) => {
      if (error?.errorFields?.length && options?.validationNotification) {
        if (error?.errorFields[0]?.name) {
          // Scroll to first error
          form.scrollToField(error?.errorFields[0]?.name, {
            behavior: 'smooth',
            block: 'center',
          });
        }

        error?.errorFields.forEach((fieldErrors?: FieldError) => {
          // Show notification of fields with error.
          fieldErrors?.errors?.forEach((fieldError) => {
            notification.error({ message: fieldError });
          });
        });
      }

      if (error?.response?.errors) {
        error?.response?.errors.forEach((itemError: any) => {
          // Show BE error notification
          if (options?.apiNotification && itemError?.extensions?.message?.length) {
            notification.error({ message: itemError?.extensions?.message });
          }

          if (itemError?.extensions?.validation) {
            const formErrors: FieldData[] = [];

            Object.keys(itemError?.extensions?.validation).forEach((itemKey) => {
              const parsedKey = itemKey.split('.');
              const key = parsedKey[0];

              const currentItemErrors = itemError?.extensions?.validation[itemKey];

              if (currentItemErrors?.length) {
                // eslint-disable-next-line react/no-danger
                const currentError = <span dangerouslySetInnerHTML={{ __html: currentItemErrors[0] }} />;

                formErrors.push({
                  name: key,
                  errors: [currentError as any],
                });

                if (options?.apiNotification) {
                  // Show field error validation from BE
                  notification.error({ message: currentError });
                }
              }
            });

            if (form) {
              form.setFields(formErrors);
            }

            if (setErrors) {
              setErrors(formErrors);
            }
          }
        });
      }
    },
    [form, setErrors],
  );

  const clearFieldErrorOnChange = useCallback(
    (values: any) => {
      Object.keys(values).forEach((field) => {
        const error = form.getFieldError(field);

        if (!error.length) {
          return;
        }
        // Clear error message of field
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ]);
      });
    },
    [form],
  );

  return { clearFieldErrorOnChange, handleErrors };
}
