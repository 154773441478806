import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetManufacturersVariables, useGetManufacturers } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useManufacturers(variables: GetManufacturersVariables = { first: 15, page: 1 }) {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useGetManufacturers(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_HOURS_IN_MILLISECONDS,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      console.log(Date.now(), 'Fetching manufacturers succeed');
    },
  });

  useQueryStatusLogging(queryInfo, 'manufacturers');

  return {
    ...queryInfo,
  };
}
