import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { InitialDataFunction, useQueryClient } from '@tanstack/react-query';
import { useGraphQLClient } from 'providers';
import { useMemo } from 'react';
import { GetTripDetails, GetTripDetailsVariables, GetTrips, useGetTripDetails } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useTripDetails(
  variables: GetTripDetailsVariables,
  initialData?: InitialDataFunction<GetTripDetails>,
  enabled = true,
) {
  const queryClient = useQueryClient();
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const localInitialData = useMemo<GetTripDetails | undefined>(() => {
    const tripsQueries = queryClient.getQueriesData<GetTrips>(['getTrips']);
    let initialTripData;

    for (let i = 0; i < tripsQueries.length; i += 1) {
      const [f, tripsData] = tripsQueries[i];
      const trip = tripsData?.trips?.data.find((item) => item.id === variables.trip_id);

      if (trip) {
        initialTripData = { trip };
        break;
      }
    }

    return initialTripData;
  }, [queryClient, variables.trip_id]);

  const queryInfo = useGetTripDetails(graphQLClient, variables, {
    cacheTime: TWO_MINUTES_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    enabled: !!accessToken && enabled,
    initialData: initialData || localInitialData,
    onSuccess: () => {
      console.log(Date.now(), `Fetching trip details: [${variables.trip_id}] succeed`);
    },
  });

  useQueryStatusLogging(queryInfo, `tripDetails: [${variables.trip_id}]`);

  return queryInfo;
}
