import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetTripsVariables, useGetTrips, OperatorType, Conjunction } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';

/**
 *
 * @param variables of the trips query
 * @param enabled query on not
 * @returns react query result
 */
export default function useTrips(variables?: GetTripsVariables, enabled = true) {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetTrips(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    keepPreviousData: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: !!accessToken && enabled,
  });

  useQueryStatusLogging(queryInfo, 'getTrips');

  return {
    ...queryInfo,
  };
}
