import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useGetFormInfo } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useFormInfo() {
  const { graphQLClient } = useGraphQLClient();

  const initialValues = {
    categories: [
      {
        id: '25',
        name: 'Antique and Classic (Power)',
      },
      {
        id: '26',
        name: 'Antique and Classic (Sail)',
      },
      {
        id: '27',
        name: 'Bowrider',
      },
      {
        id: '28',
        name: 'Catamaran',
      },
      {
        id: '29',
        name: 'Center Cockpit',
      },
      {
        id: '30',
        name: 'Center Console',
      },
      {
        id: '31',
        name: 'Convertible',
      },
      {
        id: '32',
        name: 'Cruise Ship',
      },
      {
        id: '33',
        name: 'Cruiser (Power)',
      },
      {
        id: '34',
        name: 'Cruiser (Sail)',
      },
      {
        id: '35',
        name: 'Cruiser/Racer',
      },
      {
        id: '36',
        name: 'Cuddy Cabin',
      },
      {
        id: '37',
        name: 'Cutter',
      },
      {
        id: '38',
        name: 'Daysailer',
      },
      {
        id: '39',
        name: 'Deck Saloon',
      },
      {
        id: '40',
        name: 'Dual Console',
      },
      {
        id: '41',
        name: 'Express Cruiser',
      },
      {
        id: '42',
        name: 'Flybridge',
      },
      {
        id: '43',
        name: 'Freshwater Fishing',
      },
      {
        id: '44',
        name: 'Gulet (Power)',
      },
      {
        id: '45',
        name: 'Gulet (Sail)',
      },
      {
        id: '46',
        name: 'High Performance',
      },
      {
        id: '47',
        name: 'Inflatable',
      },
      {
        id: '48',
        name: 'Ketch',
      },
      {
        id: '49',
        name: 'Mega Yacht',
      },
      {
        id: '50',
        name: 'Motor Yacht',
      },
      {
        id: '51',
        name: 'Motorsailer (Power)',
      },
      {
        id: '52',
        name: 'Motorsailer (Sail)',
      },
      {
        id: '53',
        name: 'Multi-Hull',
      },
      {
        id: '54',
        name: 'Other (Power)',
      },
      {
        id: '55',
        name: 'Other (Sail)',
      },
      {
        id: '56',
        name: 'Passenger',
      },
      {
        id: '57',
        name: 'Pilothouse (Power)',
      },
      {
        id: '58',
        name: 'Pilothouse (Sail)',
      },
      {
        id: '59',
        name: 'Power Catamaran',
      },
      {
        id: '60',
        name: 'Racer',
      },
      {
        id: '61',
        name: 'Rigid Inflatable Boats (RIB)',
      },
      {
        id: '62',
        name: 'Saltwater Fishing',
      },
      {
        id: '63',
        name: 'Schooner',
      },
      {
        id: '64',
        name: 'Sloop',
      },
      {
        id: '65',
        name: 'Sport Fishing',
      },
      {
        id: '66',
        name: 'Sports Cruiser',
      },
      {
        id: '67',
        name: 'Tender (Power)',
      },
      {
        id: '68',
        name: 'Trawler',
      },
      {
        id: '69',
        name: 'Trimaran',
      },
      {
        id: '71',
        name: 'Uncategorized',
      },
      {
        id: '70',
        name: 'Unspecified (Power)',
      },
    ],
    activities: [
      {
        id: '74',
        name: 'Day Cruising',
      },
      {
        id: '77',
        name: 'Freshwater Fishing',
      },
      {
        id: '72',
        name: 'Overnight Cruising',
      },
      {
        id: '78',
        name: 'PWC',
      },
      {
        id: '73',
        name: 'Sailing',
      },
      {
        id: '76',
        name: 'Saltwater Fishing',
      },
      {
        id: '75',
        name: 'Watersports',
      },
    ],
  };

  const queryInfo = useGetFormInfo(
    graphQLClient,
    {},
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        console.log(Date.now(), 'Finished fetching FormInfo');
      },
    },
  );

  useQueryStatusLogging(queryInfo, 'getFormInfo');

  const categories = queryInfo.data?.info?.categories || initialValues.categories;
  const activities = queryInfo.data?.info?.activities || initialValues.activities;

  return {
    ...queryInfo,
    categories,
    activities,
  };
}
