import React, { FC, ReactNode, useEffect } from 'react';
import { useConfig, useUserSetupStatus } from 'providers';
import { SETUP_OPTIONS } from 'mdc-constants';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  children?: ReactNode;
}

const SetupLayout: FC<Props> = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const { setupStatusState } = useUserSetupStatus();
  const { mdcAccountUrl } = useConfig();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (user && !user.email_verified) {
        window.location.assign(`${mdcAccountUrl}/email-confirmation`);
      }

      if (setupStatusState === SETUP_OPTIONS.NOT_STARTED || setupStatusState === SETUP_OPTIONS.SELECT_BOAT) {
        window.location.assign(`${mdcAccountUrl}/setup-account?returnTo=${window.location.href}`);
      }
    }
  }, [isAuthenticated, user, setupStatusState, isLoading, mdcAccountUrl]);

  return <>{children}</>;
};

export default SetupLayout;
