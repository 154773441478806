import { useGraphQLClient } from 'providers';
import { useGetLimits, GetLimitsVariables } from '../../graphql';
import useAccessToken from '../useAccessToken';
import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';

const useLimits = (variables?: GetLimitsVariables) => {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  return useGetLimits(graphQLClient, variables, {
    enabled: !!accessToken,
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
};

export default useLimits;
