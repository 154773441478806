import { useGraphQLClient } from 'providers';
import { useFinishTrip } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useEndTripMutation() {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const mutationInfo = useFinishTrip(graphQLClient);

  return mutationInfo;
}
