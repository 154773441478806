import { useGraphQLClient } from 'providers';
import { useAddTrip } from '../graphql';

export default function useAddTripMutation() {
  const { graphQLClient } = useGraphQLClient();

  const mutationInfo = useAddTrip(graphQLClient);

  return mutationInfo;
}
