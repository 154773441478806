import React from 'react';

import styles from '@styles/Footer/header.module.scss';

import { FacebookIcon, TwitterIcon, InstagramIcon, LinkedinIcon, RedditIcon, YoutubeIcon } from '@icons';

const Header: React.FC = () => {
  const socialItems = [
    {
      url: 'https://www.facebook.com/marinedatacloud/',
      icon: <FacebookIcon />,
    },
    {
      url: 'https://twitter.com/MarineDataCloud',
      icon: <TwitterIcon />,
    },
    {
      url: 'https://www.linkedin.com/company/marinedatacloud',
      icon: <LinkedinIcon />,
    },
    {
      url: 'https://www.reddit.com/user/MarineDataCloud/',
      icon: <RedditIcon />,
    },
    {
      url: 'https://www.instagram.com/marinedatacloud',
      icon: <InstagramIcon />,
    },
    {
      url: 'https://www.youtube.com/channel/UCR5zZQwHQTE9uhDXdHgrzcA',
      icon: <YoutubeIcon />,
    },
  ];

  return (
    <div className={styles.container}>
      <ul className={styles.container__menu}>
        {socialItems.map((item, index) => {
          const { url, icon } = item;

          return (
            <li className={styles.container__item} key={item.url}>
              <a href={url} target="_blank" rel="noreferrer" className={styles.container__item__link}>
                {icon}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Header;
