import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetRelatedUsersBoatsVariables, useGetRelatedUsersBoats } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useRelatedUsersBoats(variables: GetRelatedUsersBoatsVariables) {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useGetRelatedUsersBoats(
    graphQLClient,
    variables,
    {
      cacheTime: TWO_MINUTES_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      keepPreviousData: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      enabled: !!variables.id,
      onSuccess: () => {
        console.log(Date.now(), 'Fetching getRelatedUsersBoats succeed');
      },
    },
  );

  useQueryStatusLogging(queryInfo, 'getRelatedUsersBoats');

  return {
    ...queryInfo,
  };
}
