import { GetRecentNewsVariables, useGetRecentNews } from '../../graphql/cms';
import { GraphQLClient } from 'graphql-request';
import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useConfig } from 'providers';

export function useRecentNews(variables: GetRecentNewsVariables, enabled = true) {
  const { cmsGraphQlUrl } = useConfig();

  if (!cmsGraphQlUrl || !cmsGraphQlUrl.length) {
    throw new Error('No cmsGraphQlUrl provided in config.');
  }
  const graphQLClient = new GraphQLClient(cmsGraphQlUrl || '');

  return useGetRecentNews(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_HOURS_IN_MILLISECONDS,
    keepPreviousData: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
}
