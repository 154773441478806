import { useConfig } from 'providers';
import { useCallback, useState } from 'react';
import { downloadFile } from '../utils';
import useAccessToken from './useAccessToken';

export default function useExportToExcel<T>(fileName?: string) {
  const { mdcGraphQlUrl } = useConfig();

  if (!mdcGraphQlUrl) {
    throw new Error('Missing mdcGraphQlUrl!');
  }

  const [isExporting, setIsExporting] = useState(false);
  const { data: accessToken } = useAccessToken();

  const doExportToExcel = useCallback((query: string, variables?: T) => {
    setIsExporting(true);

    return fetch(mdcGraphQlUrl || '', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/vnd.ms-excel',
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })
      .then((response: Response) => {
        /**
        * TODO: Enable access file name from BE
        * https://stackoverflow.com/questions/44866823/why-is-fetch-api-call-not-returning-the-response-headers
        * https://medium.com/@nerdyman/prompt-a-file-download-with-the-content-disposition-header-using-fetch-and-filesaver-8683faf565d0
        *  */
        const filename = response.headers.get('content-disposition');

        // .split(';')
        // .find((n) => n.includes('filename='))
        // .replace('filename=', '')
        // .trim();

        if (response.ok) { return response.blob(); }

        throw new Error('Unable to generate export!');
      })
      .then((data) => {
        downloadFile(data, `${fileName || 'export'}.xlsx`);
      }).finally(() => {
        setIsExporting(false);
      });
  }, [accessToken, fileName, mdcGraphQlUrl]);

  return {
    doExportToExcel,
    isExporting,
  };
}
