import { useGraphQLClient } from 'providers';
import { useAddBoatFromDb } from '../graphql';

export default function useAddBoatFromDBMutation() {
  const { graphQLClient } = useGraphQLClient();

  const mutationInfo = useAddBoatFromDb(graphQLClient);

  return {
    ...mutationInfo,
  };
}
