import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useGetPublicBoats, GetPublicBoatsVariables } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function usePublicBoatsPage(variables: GetPublicBoatsVariables) {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useGetPublicBoats(
    graphQLClient,
    variables,
    {
      keepPreviousData: true,
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_HOURS_IN_MILLISECONDS,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        console.log(Date.now(), 'Fetching public boats succeed');
      },
    },
  );

  useQueryStatusLogging(queryInfo, 'publicBoats');

  return queryInfo;
}
