import React, { useMemo } from 'react';
import styles from './BlogMenu.module.scss';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BlogPostCardProps } from './BlogMenu.props';
import Image from 'next/image';
import { Typography } from 'antd';
import { useBlogUrl } from '@app/hooks/useBlogUrl';

const BlogPostCard: React.FC<BlogPostCardProps> = ({ slug, title, image, excerpt, isNewsBlog }) => {
  const { getBlogUrl } = useBlogUrl({ isNewsUrl: isNewsBlog });
  const url = useMemo(() => getBlogUrl(slug), [slug, getBlogUrl]);

  return (
    <a href={url} className={styles.postItem} target="_blank" rel="noreferrer">
      <div className={styles.postImage}>
        <Image src={image.url} alt={image.alternativeText} width={75} height={75} />
      </div>
      <div className={styles.postContent}>
        <Typography.Title ellipsis={{ rows: 1 }} level={4}>
          {title}
        </Typography.Title>
        <Typography.Paragraph ellipsis={{ rows: 2 }} className={styles.excerpt}>
          {excerpt}
        </Typography.Paragraph>
      </div>
      <div className={styles.postVisit}>
        <ArrowRightOutlined />
      </div>
    </a>
  );
};

export default BlogPostCard;
