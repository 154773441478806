import { notification } from 'antd';
import { useGraphQLClient } from 'providers';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteBoat, useGetUserBoats } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useDeleteBoatMutation() {
  const { data: accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const myBoatsKey = useGetUserBoats.getKey();

  const queryInfo = useDeleteBoat(graphQLClient, {
    onError: () => {
      notification.error({ message: 'Delete boat failed, try again!' });
    },
    onSettled: () => {
      queryClient.refetchQueries(myBoatsKey);
    },
  });

  return {
    ...queryInfo,
  };
}
