/* eslint-disable max-len */
import * as React from 'react';

interface Props {
  fill?: string;
}

const VectorIcon: React.FC<Props> = ({ fill = '#ffff', ...props }) => (
  <svg width="1em" height="1em" viewBox="0 0 14 8" fill="none" {...props}>
    <path
      d="M10.777 2.902a2.285 2.285 0 00-.952-1.419 2.239 2.239 0 00-2.294-.13C7.28 1 6.95.713 6.568.513a2.678 2.678 0 00-1.237-.3h-.08c-.68.021-1.327.3-1.812.782a2.75 2.75 0 00-.808 1.817v.048a2.314 2.314 0 00-1.174.786A2.356 2.356 0 00.95 4.997l-.003.054v.073c0 .609.236 1.194.657 1.629.422.435.995.685 1.598.697h5.353c1.192 0 2.336-.48 3.18-1.333a4.576 4.576 0 001.318-3.215h-2.277zm.368 2.625A3.613 3.613 0 018.55 6.614H3.18a1.477 1.477 0 01-.974-.438 1.509 1.509 0 01-.433-.986v-.076c.003-.303.097-.598.27-.846a1.48 1.48 0 01.809-.579l.25-.07h.003a1.563 1.563 0 01.395.013 1.973 1.973 0 01-.113-.854c.04-.47.246-.91.58-1.24a1.94 1.94 0 012.538-.17c.372.282.633.69.735 1.15 0 .008.004.015.005.022a1.565 1.565 0 01.886-.675c.35-.104.727-.08 1.062.067.335.148.609.41.773.74.164.33.21.708.126 1.068h2.035a3.697 3.697 0 01-.982 1.787z"
      fill={fill}
    />
  </svg>
);

const MemoVectorIcon = React.memo(VectorIcon);

export { MemoVectorIcon as VectorIcon };
