import { useGraphQLClient } from 'providers';
import { useAddChecklistComment } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useAddChecklistCommentMutation() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const mutationInfo = useAddChecklistComment(graphQLClient);

  return {
    ...mutationInfo,
  };
}
