import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useQueryClient, QueryKey, UseQueryOptions, InitialDataFunction } from '@tanstack/react-query';
import { useGraphQLClient } from 'providers';
import { useMemo } from 'react';
import { useGetLog, GetLogVariables, GetLog, GetTripLogs, GetAllLogs } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useLog(
  variables: GetLogVariables,
  tripId?: string,
  options?: UseQueryOptions<GetLog, unknown, GetLog, QueryKey> | undefined,
  initialData?: InitialDataFunction<GetLog>,
) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryClient = useQueryClient();

  const localInitialData = useMemo<any>(() => {
    let initialLogData;

    if (variables.id && tripId) {
      const taskLogsQueries = queryClient.getQueriesData<GetTripLogs>({ queryKey: ['getTripLogs'], exact: false });

      for (let i = 0; i < taskLogsQueries.length; i += 1) {
        const [f, logData] = taskLogsQueries[i];
        const log = logData?.logsOfTrip?.data.find((item) => item.id === variables.id);

        if (log) {
          initialLogData = { log };
          break;
        }
      }
      // console.log(initialLogData, 'Inital log data');
    }

    return initialLogData;
  }, [variables.id, tripId, queryClient]);

  const localInitialDataFromAllLogs = useMemo<any>(() => {
    let initialLogData;
    const allLogsQuery = queryClient.getQueriesData<GetAllLogs>({ queryKey: ['getAllLogs'], exact: false });

    for (let i = 0; i < allLogsQuery.length; i += 1) {
      const [f, logData] = allLogsQuery[i];
      const log = logData?.flatLogs?.data.find((item) => item.id === variables.id);

      if (log) {
        initialLogData = { log };
        break;
      }
    }
    // console.log(initialLogData, 'Initial log data from localInitialDataFromAllLogs');

    return initialLogData;
  }, [queryClient, variables.id]);

  const queryInfo = useGetLog(graphQLClient, variables, {
    ...options,
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    enabled: !!accessToken && !!variables.id,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: localInitialData || localInitialDataFromAllLogs || initialData,
  });

  return {
    ...queryInfo,
  };
}
