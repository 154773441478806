import { message } from 'antd';
import { useGraphQLClient } from 'providers';
import { useDeleteTask } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useDeleteTaskMutation() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useDeleteTask(graphQLClient, {
    onSuccess: () => {
      message.success('Task deleted successfully');
    },
    onError: (data: { message: string }) => {
      message.error(data.message.split(':')[0]);
    },
  });

  return {
    ...queryInfo,
  };
}
