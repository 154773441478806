import { HeartOutlined, LaptopOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { UserDropdownProps } from 'sailor-ui/UserDropdown';
import { BoatIcon } from 'sailor-ui/CustomIcons';
import { getAppConfiguration } from '@utils/browserEnv';

const { mdcAccountUrl, theboatAppUrl } = getAppConfiguration();

export const userMenuItems: UserDropdownProps['menuItems'] = [
  {
    key: 'my-profile',
    title: 'Account',
    items: [
      {
        key: 'profile',
        url: mdcAccountUrl,
        icon: <UserOutlined />,
        text: 'Profile',
        target: '_blank',
        isRouter: false,
      },
      {
        key: 'user-settings',
        url: `${mdcAccountUrl}/settings`,
        icon: <SettingOutlined />,
        text: 'Settings',
        isRouter: false,
        target: '_blank',
      },
      {
        key: 'activity-log',
        url: '/activity-log',
        icon: <LaptopOutlined />,
        text: 'Activity log',
        isRouter: true,
      },
    ],
  },
  {
    key: 'boats',
    title: 'Boats',
    items: [
      {
        key: 'my-boats',
        url: `${theboatAppUrl}/my-boats`,
        icon: <BoatIcon />,
        text: 'My Boats',
        target: '_blank',
        isRouter: false,
      },
      {
        key: 'favourite-boats',
        url: '/favourite-boats',
        icon: <HeartOutlined />,
        text: 'Favourite Boats',
        isRouter: true,
      },
    ],
  },
];
