import { FC } from 'react';
import style from './PlanIcon.module.scss';
import { PlanIconProps } from './PlanIcon.props';
import { VectorIcon } from './VectorIcon';
import cn from 'classnames';

const PlanIcon: FC<PlanIconProps> = ({ name, styles }) => {
  if (name) {
    return (
      <span className={cn(style[name], style.icon)} style={styles}>
        <span className={style.inner}>
          <VectorIcon />
        </span>
      </span>
    );
  }

  return null;
};

export { PlanIcon };
