import { useGraphQLClient } from 'providers';
import { GetPublicBoatDetails, useGetPublicBoatDetails } from '../graphql';
import useAccessToken from './useAccessToken';
import { useQueryStatusLogging } from './useQueryStatusLogging';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

export default function usePublicBoatDetails(
  id?: string,
  options?: UseQueryOptions<GetPublicBoatDetails, unknown, GetPublicBoatDetails, QueryKey>,
) {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetPublicBoatDetails(
    graphQLClient,
    {
      id: id || '',
    },
    {
      enabled: !!id,
      ...options,
    },
  );

  useQueryStatusLogging(queryInfo, 'publicBoatDetails');

  return queryInfo;
}
