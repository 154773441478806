import { Menu, Typography } from 'antd';
import { useMemo, FC } from 'react';
import styles from 'sailor-ui/styles/Header.module.scss';
import { useMoreMenuItems } from './useMoreMenuItems';

const { Title } = Typography;

const MoreMenu: FC = () => {
  const moreMenuItems = useMoreMenuItems();
  const menu = useMemo(() => <Menu items={moreMenuItems} className={styles.moreMenu} />, [moreMenuItems]);

  return (
    <>
      <Title level={5} style={{ color: 'var(--gray-1)', marginLeft: 16 }}>
        More
      </Title>
      {menu}
    </>
  );
};

export default MoreMenu;
