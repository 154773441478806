import { useQueryClient } from '@tanstack/react-query';
import { useGraphQLClient } from 'providers';
import { useDeleteTrip } from '../graphql';
import useAccessToken from './useAccessToken';
import { userTripsScreenVariables } from '../queryClient/defaultQueryVariables';

export default function useDeleteTripMutation() {
  const { data: accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  const { graphQLClient } = useGraphQLClient(accessToken);
  // const { handleQueryErrors } = useQueryErrors();

  const mutationInfo = useDeleteTrip(graphQLClient, {
    onError: (errors: any) => {
      // handleQueryErrors(errors);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getStatistics']);
      queryClient.invalidateQueries(['getTrips']);
      queryClient.invalidateQueries(['getTrips', userTripsScreenVariables]);
    },
  });

  return mutationInfo;
}
