import { ONE_DAY_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useQueryStatusLogging } from './useQueryStatusLogging';
import { useGetCurrencies } from '../graphql';

export default function useCurrencies() {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useGetCurrencies(
    graphQLClient,
    {},
    {
      cacheTime: ONE_DAY_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    },
  );

  useQueryStatusLogging(queryInfo, 'currencies');

  return {
    ...queryInfo,
  };
}
