import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Link from 'next/link';
import { FC, useState } from 'react';
import { MobileMenuProps } from './BoatsMenu.props';

const MobileBoatsMenu: FC<MobileMenuProps> = ({ items }) => {
  const [menuOpen, setMenuOpen] = useState<string>();
  const openDropdown = (path: string): void => {
    setMenuOpen((prevState) => {
      if (prevState === path) {
        return undefined;
      }

      return path;
    });
  };

  return (
    <Menu
      mode="inline"
      items={[
        {
          key: 'boats',
          label: 'Boats',
        },
        ...items.map((group, index) => ({
          key: group.path,
          className: 'dropdownMenuTrigger',
          label: (
            <span>
              {group.title}
              <DownOutlined
                style={{
                  transform: `rotate(${menuOpen === group.path ? 180 : 0}deg)`,
                }}
              />
            </span>
          ),
          onTitleClick: () => {
            openDropdown(group.path);
          },
          children: [
            ...group.items.map((item) => ({
              key: item.path,
              label: <Link href={item.path}>{item.title}</Link>,
            })),
            group.path && {
              key: `more:${group.path}`,
              label: (
                <Link href={group.path} style={{ display: 'flex', alignItems: 'center' }}>
                  View all
                  <RightOutlined style={{ fontSize: '13px' }} />
                </Link>
              ),
            },
          ],
        })),
      ]}
    />
  );
};

export { MobileBoatsMenu };
