import { SearchOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { SearchPanel } from '@components/SearchPanel';
import { Button, Grid, Menu, MenuProps, Modal } from 'antd';
import Link from 'next/link';
import { useCallback, useMemo, useState } from 'react';
import { HelpCenter } from 'sailor-ui/HelpCenter';
import styles from 'sailor-ui/styles/Header.module.scss';
import { UserDropdown } from 'sailor-ui/UserDropdown';
import { userMenuItems } from './UserNav/userMenuItems';

const RightMenu: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [showSearchModal, setShowSearchModal] = useState(false);

  const closeSearchModal = () => setShowSearchModal(false);

  const handleLogin = useCallback(async () => {
    await loginWithRedirect({
      appState: { targetUrl: window?.location?.href },
    });
  }, [loginWithRedirect]);

  const { xl } = Grid.useBreakpoint();

  const searchModalTop = useMemo(() => (xl ? 20 : 0), [xl]);

  const menuItems = useMemo((): MenuProps['items'] => {
    const searchItem: MenuProps['items'][number] = {
      key: 'search-menu',
      label: 'Search',
      icon: <SearchOutlined />,
      onClick: () => {
        setShowSearchModal(true);
      },
      className: 'searchMenuTrigger',
    };

    if (isAuthenticated) {
      return [searchItem];
    }

    return [
      searchItem,
      {
        key: 'login',
        label: (
          <Button type="link" onClick={handleLogin} block className={styles.loginButtonDrawer}>
            Login
          </Button>
        ),
      },
      {
        key: 'signUp',
        label: (
          <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-block ant-btn-special">
            Sign up for free
          </Link>
        ),
      },
    ];
  }, [isAuthenticated, handleLogin]);

  return (
    <>
      {xl ? (
        <div className={styles.rightMenuDesktop}>
          <Button onClick={() => setShowSearchModal(true)} icon={<SearchOutlined />} size="large" />
          {!isAuthenticated ? (
            <>
              <Button type="link" onClick={handleLogin} className={styles.loginButtonDesktop}>
                Login
              </Button>
              <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-special">
                Sign up for free
              </Link>
            </>
          ) : (
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <HelpCenter app="tbd" />
              <UserDropdown isNextJs renderLink={Link} menuItems={userMenuItems} />
            </div>
          )}
        </div>
      ) : (
        <Menu mode="inline" className="rightMenu" items={menuItems} />
      )}
      <Modal
        className={styles.searchModal}
        footer={null}
        style={{ top: searchModalTop }}
        width={1200}
        open={showSearchModal}
        onCancel={closeSearchModal}
      >
        <SearchPanel isShown={showSearchModal} closeSearchModal={closeSearchModal} />
      </Modal>
    </>
  );
};

export default RightMenu;
