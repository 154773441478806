import { useGraphQLClient } from 'providers';
import { useCompleteTask } from '../graphql';

export default function useCompleteTaskMutation() {
  const { graphQLClient } = useGraphQLClient();
  const mutateInfo = useCompleteTask(graphQLClient);

  return {
    ...mutateInfo,
  };
}
