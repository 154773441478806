import { MetaDataInterface } from '@interfaces/metaData';
import { Layout as AntLayout } from 'antd';
import Head from 'next/head';
import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import MetaData from '../MetaData';
import SetupLayout from '../SetupLayout';
import styles from './Layout.module.scss';
import cn from 'classnames';

type Props = {
  children: JSX.Element;
  meta: MetaDataInterface;
  whiteBackground?: boolean;
};

export const Layout: React.FC<Props> = ({ children, meta, whiteBackground = true }) => (
  <SetupLayout>
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>

    <MetaData {...meta} />
    <AntLayout className={cn({ [styles.antdLayoutWhite]: whiteBackground })}>
      <Header />

      {children}

      <Footer />

      <script
        dangerouslySetInnerHTML={{
          __html:
            "(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');",
        }}
      />
      <script
        data-domains="theboatdb.com, theboatapp.com, app.theboatdb.com, account.marinedatacloud.com, account.marinedatacloud.com"
        async
        src="https://r.wdfl.co/rw.js"
        data-rewardful="df7ca5"
      />
    </AntLayout>
  </SetupLayout>
);
