import { useGraphQLClient } from 'providers';
import { InitialDataFunction } from '@tanstack/react-query';
import { GetMultipleBoats, Scalars, useGetMultipleBoats, GetMultipleBoatsVariables } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';
import { useMemo } from 'react';

export default function useMultipleBoats(
  variables: GetMultipleBoatsVariables,
  initialData?: GetMultipleBoats | InitialDataFunction<GetMultipleBoats>,
) {
  const { graphQLClient } = useGraphQLClient();

  const hasIds = useMemo(() => {
    if (typeof variables?.ids === 'number') {
      return true;
    } else if (Array.isArray(variables?.ids) && variables?.ids.every((item) => typeof item === 'number')) {
      return true;
    }

    return false;
  }, [variables?.ids]);

  const queryInfo = useGetMultipleBoats(graphQLClient, variables, {
    initialData,
    keepPreviousData: true,
    enabled: hasIds,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    // onSuccess: (data) => {
    //   console.log(Date.now(), 'Fetching getMultipleBoats succeed');
    // },
  });

  useQueryStatusLogging(queryInfo, 'getMultipleBoats');

  return {
    ...queryInfo,
  };
}
