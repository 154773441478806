import { useGraphQLClient } from 'providers';
import { PlanOfUserQuery, PlanOfUserQueryVariables, usePlanOfUserQuery } from '../../graphql';
import useAccessToken from '../useAccessToken';
import { ESSENTIALS_SLUG, PLUS_SLUG, TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import useSettings from '../useSettings';
import useCurrentUser from '../useCurrentUser';
import { useQuery, UseQueryResult } from '@tanstack/react-query';


export const usePlanOfUser = (variables?: PlanOfUserQueryVariables) => {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const { getSettingByKey } = useSettings();
  const { data: currentUserData } = useCurrentUser();

  const queryInfo = usePlanOfUserQuery(graphQLClient, variables, {
    enabled: !!accessToken,
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
  });

  const isFree = useMemo(() => !queryInfo.data?.planOfUser?.slug, [queryInfo.data?.planOfUser]);

  const isOnActiveGrace = useMemo(
    () => queryInfo?.data?.planOfUser?.status === 'active' && queryInfo?.data?.planOfUser?.is_on_grace,
    [queryInfo?.data?.planOfUser?.status, queryInfo?.data?.planOfUser?.is_on_grace],
  );

  const currencySymbol = useMemo(() => {
    const currencyMap = new Map([
      ['euro', '€'],
      ['eur', '€'],
      ['usd', '$'],
    ]);

    if (queryInfo.data?.planOfUser?.currency) {
      if (currencyMap.has(queryInfo.data?.planOfUser?.currency)) {
        return currencyMap.get(queryInfo.data?.planOfUser?.currency);
      }
    }

    return '€';
  }, [queryInfo.data?.planOfUser?.currency]);

  const subscriptionEnd = useMemo(() => {
    if (queryInfo?.data?.planOfUser?.end_date) {
      try {
        return dayjs(queryInfo?.data?.planOfUser?.end_date).format(getSettingByKey('date_format'));
      } catch (error) {
        console.log('Unable to parse subscriptionEnd');
      }
    }

    return '---';
  }, [getSettingByKey, queryInfo?.data?.planOfUser?.end_date]);

  const recurrence = useMemo(() => {
    const recurrenceMap = new Map([
      ['year', 'Annually'],
      ['month', 'Monthly'],
    ]);

    if (queryInfo?.data?.planOfUser?.interval) {
      if (recurrenceMap.has(queryInfo?.data?.planOfUser?.interval)) {
        return recurrenceMap.get(queryInfo?.data?.planOfUser?.interval);
      }
    }

    return queryInfo?.data?.planOfUser?.interval;
  }, [queryInfo?.data?.planOfUser?.interval]);

  const totalCosts = useMemo(() => {
    if (isFree) {
      return 0;
    }

    if (queryInfo?.data?.planOfUser?.total && queryInfo?.data?.planOfUser?.cost) {
      if (queryInfo?.data?.planOfUser?.total > 0) {
        return queryInfo?.data?.planOfUser?.total * queryInfo?.data?.planOfUser?.cost;
      }
    }

    return 0;
  }, [queryInfo?.data?.planOfUser?.total, queryInfo?.data?.planOfUser?.cost, isFree]);

  const subscriptionSince = useMemo(() => {
    if (queryInfo?.data?.planOfUser?.first_subscription_date) {
      try {
        return dayjs(queryInfo?.data?.planOfUser?.first_subscription_date).format(getSettingByKey('date_format'));
      } catch (error) {
        console.log('Unable to calculate subscriptionSince');
      }
    }

    return '---';
  }, [getSettingByKey, queryInfo?.data?.planOfUser?.first_subscription_date]);

  const currentUserSubscription = useMemo(() => {
    if (isFree) {
      return {
        available: currentUserData?.authUser?.subscription?.has_free_boat_available ? 1 : 0,
        total: 1,
        invalid: 0,
        cost: 0,
        used: currentUserData?.authUser?.subscription?.has_free_boat_available ? 0 : 1,
        has_free_boat_available: currentUserData?.authUser?.subscription?.has_free_boat_available,
      };
    }

    if (queryInfo.data?.planOfUser?.slug) {
      return {
        available: queryInfo.data?.planOfUser.available,
        total: queryInfo.data?.planOfUser.total,
        invalid: queryInfo.data?.planOfUser.invalid,
        cost: queryInfo.data?.planOfUser.cost,
        used: queryInfo.data.planOfUser.used,
        ...queryInfo.data?.planOfUser,
        has_free_boat_available: currentUserData?.authUser?.subscription?.has_free_boat_available,
      };
    }
  }, [currentUserData?.authUser?.subscription?.has_free_boat_available, isFree, queryInfo.data?.planOfUser]);

  const isSubscribed = useMemo<boolean>(() => {
    if (!currentUserData?.authUser?.subscription?.current_subscription) {
      return false;
    }

    if (currentUserData?.authUser?.subscription?.current_subscription === 'free') {
      return false;
    }

    return true;
  }, [currentUserData?.authUser?.subscription?.current_subscription]);

  const isFreeUser: boolean = useMemo<boolean>(() => !isSubscribed, [isSubscribed]);

  const isTrialUser = useMemo<boolean>(
    () =>
      currentUserData?.authUser?.subscription?.trial_ends_at && currentUserData?.authUser?.subscription?.is_on_trial,
    [currentUserData?.authUser?.subscription?.is_on_trial, currentUserData?.authUser?.subscription?.trial_ends_at],
  );

  const trialEndsAt = useMemo<Dayjs>(
    () => dayjs(currentUserData?.authUser?.subscription?.trial_ends_at),
    [currentUserData?.authUser?.subscription?.trial_ends_at],
  );

  const isBetaUser = useMemo(
    () => currentUserData?.authUser?.subscription?.is_beta_user === true,
    [currentUserData?.authUser?.subscription?.is_beta_user],
  );

  const user = useMemo(() => currentUserData?.authUser, [currentUserData?.authUser]);

  const canUpgrade = useMemo<boolean>(() => {
    if (isFreeUser) {
      return true;
    }

    if (user?.current_subscription === PLUS_SLUG || user?.current_subscription === ESSENTIALS_SLUG) {
      return true;
    }

    return false;
  }, [isFreeUser, user?.current_subscription]);

  const hasScheduledUpdate = useMemo<boolean>(
    () => !!queryInfo?.data?.planOfUser?.has_scheduled_update,
    [queryInfo?.data?.planOfUser?.has_scheduled_update],
  );

  const hasPaymentMethod = useMemo<boolean>(
    () => !!currentUserData?.authUser?.card_last_four,
    [currentUserData?.authUser?.card_last_four],
  );

  return {
    ...queryInfo,
    user,
    currencySymbol,
    subscriptionEnd,
    recurrence,
    totalCosts,
    subscriptionSince,
    isFree,
    currentUserSubscription,
    canUpgrade,
    isBetaUser,
    trialEndsAt,
    isTrialUser,
    isSubscribed,
    isOnActiveGrace,
    hasScheduledUpdate,
    hasPaymentMethod,
  };
};
