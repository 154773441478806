import { useGraphQLClient } from 'providers';
import { useValidateFileSize } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useValidateFile() {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useValidateFileSize(graphQLClient);

  return {
    ...queryInfo,
  };
}
