import { GetBoatsActivitiesVariables } from '../../graphql/cms';

const BOAT_ACTIVITIES_HOMEPAGE_QUERY_LIMIT = 14;

export const activityBoatsHomePageQueryVariables: GetBoatsActivitiesVariables = {
  limit: BOAT_ACTIVITIES_HOMEPAGE_QUERY_LIMIT,
  filters: {
    publicBoatsCount: { gte: 1 },
  },
};
