import { TWO_HOURS_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetSearchedUsersVariables, useGetSearchedUsers } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useSearchUser(variables: GetSearchedUsersVariables, enabled = false) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  // const { handleQueryErrors } = useQueryErrors();
  const queryInfo = useGetSearchedUsers(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    refetchOnMount: false,
    enabled: !!accessToken && enabled,
    // onError: (errors: any) => {
    //   handleQueryErrors(errors);
    // },
  });

  return queryInfo;
}
