import { message } from 'antd';
import { useGraphQLClient } from 'providers';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteLog } from '../graphql';
import useAccessToken from './useAccessToken';

const useDeleteLogMutation = () => {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const queryInfo = useDeleteLog(graphQLClient, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getTripLogs']);
    },
    onError: (data: { message: string }) => {
      // message.error(data.message.split(':')[0]);
      message.error("Log couldn't be deleted");
    },
  });

  return {
    ...queryInfo,
  };
};

export default useDeleteLogMutation;
