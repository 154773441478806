import { DownOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useCurrentUser, useFilesUrlWithToken } from 'services/hooks';
import { usePlanOfUser } from 'services/hooks/queries/usePlanOfUser';
import styles from './UserDropdown.module.scss';
import { UserDropdownProps } from './UserDropdown.props';
import UserMenu from './UserMenu.component';

const UserAvatar: FC<UserDropdownProps> = ({ displayName, menuItems, renderLink, isNextJs, extra }) => {
  const [visible, setVisible] = useState(false);
  const { isSubscribed } = usePlanOfUser();
  const { data: userData } = useCurrentUser();

  const userInitials = useMemo(() => {
    const nameFirstChar = userData?.authUser?.name?.substring(0, 1).toLocaleUpperCase();
    const surnameFirstChar = userData?.authUser?.surname?.substring(0, 1).toLocaleUpperCase();

    if (nameFirstChar && surnameFirstChar) {
      return nameFirstChar + surnameFirstChar;
    }

    return '';
  }, [userData?.authUser?.name, userData?.authUser?.surname]);

  const { fileUrlWithToken } = useFilesUrlWithToken();

  const profilePictureUrl = useMemo(
    () => fileUrlWithToken(userData?.authUser?.image?.url),
    [userData?.authUser?.image?.url, fileUrlWithToken],
  );

  const handleVisibleChange = (change: boolean) => {
    setVisible(change);
  };

  const handleHideUserDropdown = () => {
    setVisible(false);
  };

  return (
    <Dropdown
      open={visible}
      onOpenChange={handleVisibleChange}
      dropdownRender={() => (
        <UserMenu
          menuItems={menuItems}
          Link={renderLink}
          isNextJs={isNextJs}
          hideMenu={handleHideUserDropdown}
          extra={extra}
        />
      )}
      trigger={['click']}
      className={styles.container}
      overlayClassName={styles.userNavDropdown}
      overlayStyle={{ minWidth: '200px' }}
      arrow={{ pointAtCenter: true }}
      openClassName={styles.userDropdownOpen}
    >
      <div>
        <Avatar src={profilePictureUrl} size={32} className={styles.containerAvatar}>
          {userInitials}
        </Avatar>

        {displayName && (
          <Button type="text" className={styles.displayName}>
            {userData?.authUser?.name} {userData?.authUser?.surname}
          </Button>
        )}

        <div className={styles.downIconWrapper}>
          <DownOutlined className={styles.downIcon} />
        </div>
      </div>
    </Dropdown>
  );
};

export default UserAvatar;
