import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetPublicChecklistVariables, useInfiniteGetPublicChecklist } from '../graphql';
import { useQueryClient } from '@tanstack/react-query';
import { GetPublicChecklistDataItem } from '../types/checklists';
import useAccessToken from './useAccessToken';

function updateChecklistsCacheAfterUnfollowFn(followedItem: GetPublicChecklistDataItem) {
  return (old: any) => ({
    ...old,
    pages: old.pages.map((page: any) => ({
      ...page,
      publicChecklists: {
        ...page.publicChecklists,
        data: page.publicChecklists.data.map((checklist: any) => {
          if (checklist.id === followedItem.id) {
            return followedItem;
          }

          return checklist;
        }),
      },
    })),
  });
}

function updateChecklistsCacheAfterFollowFn(followedItem: GetPublicChecklistDataItem) {
  return (old: any) => ({
    ...old,
    pages: old.pages.map((page: any) => ({
      ...page,
      publicChecklists: {
        ...page.publicChecklists,
        data: page.publicChecklists.data.map((checklist: any) => {
          if (checklist.id === followedItem.id) {
            return followedItem;
          }

          return checklist;
        }),
      },
    })),
  });
}

function updateChecklistCacheAfterDeleteFn(deletedIds: string) {
  return (old: any) => ({
    ...old,
    pages: old.pages.map((page: any) => ({
      ...page,
      publicChecklists: {
        ...page.publicChecklists,
        data: page.publicChecklists.data.filter((e: any) => !deletedIds.includes(e.id)),
      },
    })),
  });
}

export default function useInfinitePublicChecklists(
  variables: GetPublicChecklistVariables,
  searchQuery: string | null,
) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const queryInfo = useInfiniteGetPublicChecklist(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    enabled: !!accessToken,
    getNextPageParam: ({ publicChecklists }) => {
      const { currentPage, hasMorePages } = publicChecklists?.paginatorInfo || {};

      if (hasMorePages && currentPage) {
        return currentPage;
      }

      return undefined;
    },
  });

  return {
    ...queryInfo,

    updatePublicChecklistsCacheAfterUnfollow: (followedItem: GetPublicChecklistDataItem) => {
      queryClient.cancelQueries(['getPublicChecklist.infinite', { keyword: searchQuery || '' }]);
      queryClient.setQueryData(
        ['getPublicChecklist.infinite', { keyword: searchQuery || '' }],
        updateChecklistsCacheAfterUnfollowFn(followedItem),
      );
    },

    updatePublicChecklistsCacheAfterFollow: (followedItem: GetPublicChecklistDataItem) => {
      queryClient.cancelQueries(['getPublicChecklist.infinite', { keyword: searchQuery || '' }]);
      queryClient.setQueryData(
        ['getPublicChecklist.infinite', { keyword: searchQuery || '' }],
        updateChecklistsCacheAfterFollowFn(followedItem),
      );
    },

    updatePublicChecklistCacheAfterDelete: (deleteIds: string) => {
      queryClient.cancelQueries(['getPublicChecklist.infinite', { keyword: searchQuery || '' }]);
      queryClient.setQueryData(
        ['getPublicChecklist.infinite', { keyword: searchQuery || '' }],
        updateChecklistCacheAfterDeleteFn(deleteIds),
      );
    },
  };
}
