import { useGraphQLClient } from 'providers';
import { useAddTask } from '../graphql';

export default function useAddTaskMutation() {
  const { graphQLClient } = useGraphQLClient();
  const mutationInfo = useAddTask(graphQLClient);

  return {
    ...mutationInfo,
  };
}
