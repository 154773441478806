import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetMakersVariables, useGetMakers } from '../graphql';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useMakers(variables?: GetMakersVariables) {
  const { graphQLClient } = useGraphQLClient();

  const queryInfo = useGetMakers(
    graphQLClient,
    {
      first: variables?.first || 1000,
      page: variables?.page,
    },
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        console.log(Date.now(), 'Finished fetching makers');
      },
    },
  );

  useQueryStatusLogging(queryInfo, 'manufacturers');

  return {
    ...queryInfo,
  };
}
