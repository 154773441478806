import { RightOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';
import styles from './HelpCenter.module.scss';
import { helpLinks } from './helpLinks';

export type HelpCenterMenuProps = {
  isDrawer?: boolean;
  className?: string;
  app?: 'mdc' | 'tba' | 'tbd';
  noGroup?: boolean;
};

export const HelpCenterMenu: React.FC<HelpCenterMenuProps> = ({ app, isDrawer, className, noGroup }) => {
  const menuItems = useMemo(
    (): MenuProps['items'] =>
      helpLinks.map((item) => {
        let url = '#';

        if (app) {
          url = item.url[app];
        }

        return {
          key: item.id,
          label: (
            <a href={url} target="_blank" rel="noreferrer">
              {isDrawer ? (
                item.title
              ) : (
                <div className={styles.itemWrapper}>
                  <div className={styles.itemContent}>
                    <p className={styles.itemTitle}>{item.title}</p>
                    <p>{item.text}</p>
                  </div>
                  <RightOutlined />
                </div>
              )}
            </a>
          ),
          icon: item.icon,
          className: styles.menuItem,
        };
      }),
    [isDrawer, app],
  );

  const menuItemsGroup = useMemo(
    (): MenuProps['items'] => [
      {
        label: 'Help & Support',
        key: 'help-center',
        type: 'group',
        children: menuItems,
      },
    ],
    [menuItems],
  );

  return (
    <Menu
      selectable={false}
      items={noGroup ? menuItems : menuItemsGroup}
      className={cn(
        styles.helpCenterMenu,
        { [styles.helpCenterMenuDrawer]: isDrawer },
        { [styles.helpCenterMenuDropdown]: !isDrawer },
        className,
      )}
      mode="vertical"
    />
  );
};
