import { GraphQLClient } from 'graphql-request';
import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useConfig } from 'providers';
import { GetUserFavoriteBoatsVariables, useGetUserFavoriteBoats } from '../../graphql/cms';

export default function useUserFavoriteBoats(variables: GetUserFavoriteBoatsVariables, enabled = true) {
  const { cmsGraphQlUrl } = useConfig();

  if (!cmsGraphQlUrl || !cmsGraphQlUrl.length) {
    throw new Error('No cmsGraphQlUrl provided in config.');
  }
  const graphQLClient = new GraphQLClient(cmsGraphQlUrl || '');

  const queryInfo = useGetUserFavoriteBoats(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_HOURS_IN_MILLISECONDS,
    keepPreviousData: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });

  return queryInfo;
}
