import { useGraphQLClient } from 'providers';
import { useUpdateLog } from '../graphql';

export default function useUpdateLogMutation() {
  const { graphQLClient } = useGraphQLClient();

  const mutationInfo = useUpdateLog(graphQLClient);

  return {
    ...mutationInfo,
  };
}
